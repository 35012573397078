import React, { Component } from 'react';
import $ from 'jquery';

import Login from './Login';
import Principal from './Principal';
//import{ BrowserRouter as Router, Route } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import api from './Api';
import './assets/principal.css';

import LogRocket from 'logrocket';
import Cookies from 'universal-cookie';

window.cookies = new Cookies();
window.defaultEditorOptions = ['Blockly','Código'];
window.defaultAssetsType = [
  {id:'0',name:'Image',name2:'imagem',t:'Nenhuma',name3:'Imagens'},
  {id:'1',name:'SpriteSheet',name2:'spritesheet',t:'Nenhum',name3:'Spritesheets'},
  {id:'3',name:'Áudio',name2:'áudio',t:'Nenhum',name3:'Áudios'}
];
// {id:'2',name:'Sprite',name2:'sprite',t:'Nenhum',name3:'Sprites'},

var load;

class App extends Component {

  constructor(props){

    super(props);

    if(window.cookies.get("session") !== undefined && window.cookies.get("session") !== null){

      sessionStorage.setItem('session',window.cookies.get("session"));
      sessionStorage.setItem('userId',window.cookies.get("userId"));

    }

    var session = sessionStorage.getItem('session');
    var userId = sessionStorage.getItem('userId');
    //caso não tenha, o session é vazio, isso faz o cara ir para página de login
    if(session == null){ session = ""; }
 
    //Caso envie algum parametro por URL

    var url = new URL(window.location.href);
    var get_session = url.searchParams.get("session");
    var get_user = url.searchParams.get("user");

    //caso o acesso seja por url
    if(get_session !== null && get_user !== null){

      session = get_session;
      userId = get_user;

      console.log(session);
      console.log(userId);

      sessionStorage.setItem('userId',userId);
      sessionStorage.setItem('session',session);

    }

    //console.log(get_session);
    //console.log(get_user);

    if(false){

      if(session == null){ session = "gwd/vfOZbanoSJL5j4hbJ+W5Qc19q+aA5/PnJOIVcwdRfCaraf40MvvkfiRkXPUTeNYc/SZhLyGHTAxS6X//sg=="; }
      if(userId == null){ userId = "1"; }

      sessionStorage.setItem('userId','1');
      sessionStorage.setItem('session','gwd/vfOZbanoSJL5j4hbJ+W5Qc19q+aA5/PnJOIVcwdRfCaraf40MvvkfiRkXPUTeNYc/SZhLyGHTAxS6X//sg==');

    }

    this.state = {userId:userId,session:session,carregouIp:false};

    this.setSession = this.setSession.bind(this);
    this.retornaState = this.retornaState.bind(this);
    this.removeSession = this.removeSession.bind(this);
    this.verify = this.verify.bind(this);
    this.verificaApp = this.verificaApp.bind(this);

    window.app = this;

  }

  setSession(data){

    this.setState({session:data.session,userId:data.user.id,user:data.user}); 

  }

  removeSession(){

    console.log('removeu tudo');
    sessionStorage.removeItem('session');
    sessionStorage.removeItem('userId');

    $("#header").addClass('animated');
    $("#header").addClass('fadeOutUp');

    $(".principal").fadeOut(1000);
    setTimeout(function(){ this.setState({session:'',userId:''}); }.bind(this), 1200);

  }

  retornaState(){

    return this.state;

  }

  async componentDidMount(){

    this.verificaApp();
      
  }



  async verificaApp(){


      //this.loading(true);
      $("#loadingTxt").html('Obetendo ip do backend.'); 
       
      var ip = await $.ajax({
        url: '/serverlist/'+window.location.hostname+'.json', // substitua por qualquer URL real
        async: true
      }).done(function(data){
         
        return data.responseJSON;

      })
      .fail(function(){ return false; }).catch(function(){ return false; });

      if(ip === false){ $("#loadingTxt").html('Não foi possível obter o ip.'); $("#page-loader").find('.loader-icon').fadeOut(0); return false; }

      if(ip.url === undefined){ $("#loadingTxt").html('A url não foi denifida.'); $("#page-loader").find('.loader-icon').fadeOut(0); return false; } 

      $("#loadingTxt").html('Verificando backend.'); 

      var backend = await $.ajax({
        url: ip.url, // substitua por qualquer URL real
        async: true
      }).done(function(data){
          
        return data.responseJSON;

      })
      .fail(function(){

        this.loading(true);
        console.log('falha ao acessar: '+ip.url);
        return false;

      }.bind(this)).catch(function(){

        this.loading(true);
        console.log('falha ao acessar: '+ip.url);
        return false;

      }.bind(this));

      if(backend){

        sessionStorage.setItem('backend', ip.url);
        sessionStorage.setItem('verify',ip.verify)
        sessionStorage.setItem('production',ip.production);

        console.log('Backend: '+ip.url);

        $("#loadingTxt").html(''); 
        //this.loading(false);
        setTimeout(function(){ 

          this.setState({carregouIp:true}); 
          $("#loadingTxt").html(''); 
          this.verify(1); 
          window.initSocketIo(); 

        }.bind(this), 100);
         

      }else{ $("#loadingTxt").html('Não foi possível acessar o backend.'); $("#page-loader").find('.loader-icon').fadeOut(0); }

      

  }


  loading(n){
   
    if(n){

      $('#page-loader').fadeIn(300);
      $('#styleExtra').html('<style> body{ overflow:hidden; } </style>');
      $("#page-loader").find('.loader-icon').fadeIn(0);

    }else{

      $('#page-loader').fadeOut(300);
      $('#styleExtra').html('');

    }

  }



  //para verificar no servidor se ele esta logado.
  verify(pass){


    if(this.state.session !== "" && this.state.carregouIp === true){

       pass = true;

    }
    

    if(pass === true && sessionStorage.getItem('verify') === "true"){

      api.verifyAcess({}).then((res) => {


          if(this.state.dataUser === undefined){

            this.setState({dataUser:res.data});
            //LogRocket.init('ejs9so/engine-csplus');
            //LogRocket.identify('csplus_engine_'+res.data.id,{name: res.data.name+' '+res.data.lastName,email:res.data.email,location:window.location.hostname});
  
          }else{

            if(this.state.dataUser.admin !== res.data.admin){

              this.setState({dataUser:res.data});

            }

          }
          
          
      }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);

          if(error.response !== undefined){

            if(error.response.status !== undefined){

              if(error.response.status === 401){  this.removeSession();  }

            }

          }
    
      });

    }

    setTimeout(function(){ this.verify(); }.bind(this), 3000);

  }



  render() {

    if(this.state.session === ''){ 

      load = {display:'none'}; 
      $('#styleExtra').html('');

    }else{ 

      load = {}; 
      $('#styleExtra').html('<style> body{ overflow:hidden; } </style>');

    }

    return (<>
      
      <ToastContainer/>

      <div id="page-loader" className="" style={load}>
        <div className="page-loader-inner animated fadeIn">
            <div className="loader-logo"><img src="/media/csplus-logo.svg" alt="Logo"/></div>
            <div style={{ marginTop:'30px',textAlign:'center',color:'#fff',fontSize:'19px'}} id="loadingTxt"></div>
            <div className="loader-icon"><span className="spinner"></span><span></span></div>
        </div>
      </div>

      { 

        //caso não esteja logado 
        (this.state.session === '' && this.state.carregouIp === true) &&                                                                                                 
        <>
          <Login setSession={(session) => this.setSession(session)} />
          <link rel='stylesheet' id='mqueries-style-css'  href="/css/login.css" />
        </>

      }

      {

        //caso esteja logado
        (this.state.session !== "" && this.state.carregouIp === true) && 
        <Principal logout={() => this.logout()} />

      }
  
      </>);

  }


}

export default App;
