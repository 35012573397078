import React, { Component } from 'react';
//import{ Link } from 'react-router-dom';

import $ from 'jquery';
//import api from './Api';
import Resizable from 're-resizable';
 
import TableAssets from './../table/Assets';
import FileEdit from './../table/FileEdit';
import Tooltip  from 'rc-tooltip';

require('dotenv').config();

class Phaser extends Component {
  

  constructor(props){

    super(props);

    this.alteraLargura = this.alteraLargura.bind(this);
    this.alteraAltura = this.alteraAltura.bind(this);
    this.state = {error:[]};
  
    window.phaser = this;
  }

  componentDidMount(){}

  componentDidUpdate(){

    $(".log_arrad[parse!=1]").each(function(){  
  
      $(this).attr('parse','1');
      $(this).html($(this).text())
      
    });

  }
 

  alteraLargura(valor,total){

    var largura = $('#divIframePhaser').width();
    largura = largura + valor;
    $('#divIframePhaser').css('width',largura+'px');

    if(total){

      largura = valor;

    }

    $("#wphaser").html(largura);
    window.studio_blockly.saveSettings();

  }

  alteraAltura(valor,total){

    var altura = $('#divIframePhaser').height();
    altura = altura + valor;
    $('#divIframePhaser').css('height',altura+'px');

    if(total){

      altura = valor;

    }

    $("#hphaser").html(altura);
    window.studio_blockly.saveSettings();

  }

  mostraTableBaixo(id){

    $('.optionHeaderStudio2').removeClass('ativo');
    $('.optionHeaderStudio2[idc="'+id+'"]').addClass('ativo');

    $('.tabBaixoAsset').fadeOut(0);
    $('.tabBaixoAsset[idc='+id+']').fadeIn(0);

    window.phaser.calculaAltura();
    
  }
  
  calculaAltura(){

    var altura = 0;
    altura = $(window).height();

    var outros = ( $(".tituloProjeto").height()+$("#divIframePhaser").height()+$("#as1").height()+$(".headerLeftStudio2").height()+$(".headerLeftStudio").height()+67);
    
    altura = altura - outros ;
    $("#log").css('height',altura+'px');

  }

  startGame(notEmit){


    window.assets_a.simulateDrag('remove');

    if(!$("#inputPreservarLog").is(':checked')){

      this.setState({error:[]});

    } 
  
    $('#IframePhaser').attr('src',$('#IframePhaser').attr('src_old'));




    if(notEmit === true){ return false; };

    var obj = {};
    
    obj.game = window.studio_blockly.state.game.id;
    obj.user = window.app.state.userId;

    //para emetir para o resto do pessoal
    console.log('emitiu');
    console.log(obj);
    
    window.emitir('playGame',obj);



  
  }

 
  render() {
    
  	var url = sessionStorage.getItem('backend')+'/games/generatecode/'+window.studio.props.match.params.game+'/';
  	var styleHandle = {top: {display:'none'},topLeft: {display:'none'},left: {marginTop: -5,marginLeft: -3,top: "50%",left: 0,cursor: "ew-resize",border: "3px solid #999",borderTop: "none",borderRight: "none",borderBottom: "none",borderWidth: 5,borderColor: "#09f",width: 10,height: 10,boxSizing: "border-box",zIndex: 1},bottomLeft: {marginTop: -7,marginLeft: -2,top: "100%",left: 0,cursor: "nesw-resize",border: "3px solid #999",borderRight: "none",borderTop: "none",borderColor: "#09f",width: 10,height: 10,boxSizing: "border-box",zIndex: 1},bottom: {marginTop: -7,marginLeft: -5,top: "100%",left: "50%",cursor: "ns-resize",border: "3px solid #999",borderLeft: "none",borderRight: "none",borderTop: "none",borderWidth: 5,borderColor: "#09f",width: 10,height: 10,boxSizing: "border-box",zIndex: 1},bottomRight: {marginTop: -7,marginLeft: -7,top: "100%",left: "100%",cursor: "nwse-resize",border: "3px solid #999",borderLeft: "none",borderTop: "none",borderColor: "#09f",width: 10,height: 10,boxSizing: "border-box",zIndex: 1},right: {marginTop: -5,marginLeft: -7,top: "50%",left: "100%",cursor: "ew-resize",border: "3px solid #999",borderTop: "none",borderLeft: "none",borderBottom: "none",borderWidth: 5,borderColor: "#09f",width: 10,height: 10,boxSizing: "border-box",zIndex: 1},topRight: {display:'none'}}


    return (<div> 
			     
			    	{
						( window.studio_blockly.state.game.widthcanvas !== undefined) &&
  					 
              <center>

                <div className="tituloProjeto">


                {

                (window.studio.state.debug === true) &&
                <font className="eae1" style={{cursor:'pointer'}}>
                
                  {

                    (parseInt(window.studio_blockly.state.game.debug) === 1) &&
                    <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Debug on</span>}>
                      <div onClick={ () => window.studio.debug(false) } className="option option_3 hvr-hang "> <img style={{height:'36px'}} alt="" src="/media/debug.png" /> </div>
                    </Tooltip>
     
                  }

                  {

                    (parseInt(window.studio_blockly.state.game.debug) === 0) &&
                    <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Debug off</span>}>
                      <div onClick={ () => window.studio.debug(1) } className="option option_3 hvr-hang debugon"> <img style={{height:'36px',filter:'grayscale(100%)'}} alt="" src="/media/debug.png" /> </div>
                    </Tooltip>
     
                  }

                 </font>


              }


                <img onClick={ () => this.startGame()} className="hvr-grow" src="/media/arrow_submit_play_next_right.png" alt="play" style={{height:'27px',cursor:'pointer'}} />
                <font> { window.studio_blockly.state.game.name } </font></div>

  				    	<Resizable defaultSize={{ width:window.studio_blockly.state.game.phaserwidth+'px',height:window.studio_blockly.state.game.phaserheigth+'px' }} id="divIframePhaser"  onResize={ (e, direction, ref, d) => { window.phaser.calculaAltura(); $("#wphaser").html($("#divIframePhaser").width()); $("#hphaser").html($("#divIframePhaser").height()); } }  onResizeStop={() =>  window.studio_blockly.saveSettings() } style={{marginTop:'20px'}} handleStyles={styleHandle}> 
  				    		<iframe id="IframePhaser" title="phaserframe" src_old={url} frameBorder="0" style={{boxShadow:'0px 0px 11px -4px #000',width:'100%',height:'100%',background:'#fff'}} ></iframe> 
  				    	</Resizable>

  				    	<div style={{marginTop:'5px',color:"#fff"}} id="as1">

                  <button className="alterCanvasSize" onClick={ () => this.alteraLargura(-1)}><i className="fas fa-minus" style={{color:'#fff'}}></i></button> <button onClick={ () => this.alteraLargura(1) } className="alterCanvasSize"><i className="fas fa-plus" style={{color:'#fff' }}></i></button> <font id="wphaser" >{ window.studio_blockly.state.game.phaserwidth }</font> x <font id="hphaser">{ window.studio_blockly.state.game.phaserheigth }</font>
                  <button className="alterCanvasSize" onClick={ () => this.alteraAltura(1) }><i style={{marginLeft:'4px',color:'#fff'}} className="fas fa-plus"></i></button> <button onClick={ () => this.alteraAltura(-1) } className="alterCanvasSize"><i className="fas fa-minus" style={{color:'#fff' }}></i></button> 

                </div>

                <div style={{color:"#fff"}}><font style={{marginRight:'29px'}}>Eixo x: <font id="eixo_x">0</font> </font> <font>Eixo y: <font id="eixo_y">0</font></font></div>
              
              </center>

			    	}

            <div className="headerLeftStudio2">

              <div hidden onClick={ () => this.mostraTableBaixo('TableScenes') } className="optionHeaderStudio2" idc="TableScenes" type="showScenes"><font>Scenes <i onClick={ () => window.studio_blockly.newFileByName('scene') } className="addAlgo">+</i></font></div>           
              <div onClick={ () => this.mostraTableBaixo('TableFiles') } className="optionHeaderStudio2 ativo" idc="TableFiles" type="showJs">

              <font>

                {

                  (window.studio_blockly.state.game.advanced === 1) &&
                  <font>Files</font>

                }
                
                {

                  (window.studio_blockly.state.game.advanced === 0) &&
                  <font>Scenes</font>

                }

                <i onClick={ () => window.studio_blockly.newFileByName() } className="addAlgo">+</i>

              </font>

              </div>           
              
              <div onClick={ () => this.mostraTableBaixo('TableAssets') } className="optionHeaderStudio2 " idc="TableAssets" type="showCanvas" ><font>Assets <i className="addAlgo" onClick={ () => window.uploadAssetModal.openUploadAssetModal(window.$(".optionHeaderAsset.ativo").attr('idc')) }>+</i></font></div>
              <div onClick={ () => this.mostraTableBaixo('TableConsole') } className="optionHeaderStudio2" idc="TableConsole" type="showJs"><font>Console</font> <font style={{float:'right',marginRight:'25px'}}>{this.state.error.length}</font> </div>
            
            </div>

            <div id="phaserTableBaixo">

              <div className="tabBaixoAsset" idc="TableAssets" style={{display:'none'}}><TableAssets/></div>
              <div className="tabBaixoAsset" idc="TableFiles" ><FileEdit/></div>
              <div className="tabBaixoAsset" idc="TableConsole" style={{display:'none'}}>
                
                <Tooltip placement="right" trigger={['hover']} overlay={<span>Limpar logs?</span>}>
                <i onClick={ () => this.setState({error:[]}) } className="fas fa-recycle" style={{"position":"absolute","height":"18px","fontSize":"15px","marginTop":"11px","cursor":"pointer","marginLeft":"14px"}}></i>
                </Tooltip>
                  <div> 

                  <center>

                    <Tooltip placement="right" trigger={['hover']} overlay={<span>Mantem os logs ao reiniciar.</span>}>
                  
                        <div className="form-check form-check-inline" style={{marginTop:'7px'}}>
                          <input onChange={ () => window.studio_blockly.saveSettings() } className="form-check-input" type="checkbox" id="inputPreservarLog" value="option1" />
                          <label className="form-check-label" htmlFor="inputPreservarLog" style={{color:'#fff'}}>Preservar log</label>
                        </div> 

                    </Tooltip>

                  </center>

                    
                  
                  </div>

                  <div id="log" style={{padding:'0px 10px 0px 10px',marginTop:'9px',overflowY:'auto'}}>
                    
                    {

                      (this.state.error.length >= 0) &&
                      this.state.error.map(function(item, i){

                        var img = "";
                        var classp = "alert alert-default alert-dismissible animated fadeInUp logdiv ";

                        if(item.type === "log"){ img = "/media/symbol_information.png"; }
                        if(item.type === "info"){ classp = classp+" "; img = "/media/symbol_information.png"; }
                        if(item.type === "warn"){ classp = classp+" logwarn"; img = "/media/symbol_exclamation.png"; }
                        if(item.type === "error"){ classp = classp+" logerror"; img = "/media/symbol_error.png"; }

                        return(<div key={i} style={{backgroundColor:'#f7f7f7',boxShadow:'0px 0px 0px 0.2px #000',paddingRight:'15px'}} className={classp}>
                                <button style={{display:'none'}} type="button" className="close" data-dismiss="alert"><span aria-hidden="true" hidden>×</span><span className="sr-only">Close</span></button>
                                  <img style={{height: '30px',marginRight: '5px'}} src={img} alt=""/>
                                  <div className="log_arrad" style={{display:'contents'}}>{ item.msg }</div>
                              </div>)

                      })

                    }

                    {

                      (this.state.error.length === 0) &&
                      <div className="nenhumlog animated fadeIn">
                        
                        <font>Nenhum log informado.</font>

                      </div>

                    }


                  </div>

              </div>

            </div>

    		</div>)


  }


}


export default Phaser;