
import React, { Component } from 'react';
import{ Link } from 'react-router-dom';

import $ from 'jquery';
import api from './Api';
import Swal from 'sweetalert2';

import Blockly from './studio/Blockly';
import Phaser from './studio/Phaser';

import ConfigurationModal from './modal/Configuration';
import AssocitionModal from './modal/AssocitionModal';
import AnimationModal from './modal/AnimationModal';
import ImportAssetScene from './modal/ImportAssetScene';
import AcerEditorViwer from './studio/AcerEditorViwer';
import UploadAssetModal from './modal/UploadAssetModal';
import ImportAsseAsset from './modal/ImportAsseAsset';

import './studio/css/studio.css';
import './studio/css/tabs.css';
import './studio/css/assets.css';
import './studio/css/log.css'; 
import './studio/css/customConfigAcer.css'; 

import Tooltip  from 'rc-tooltip';
import { toast } from 'react-toastify';

require('dotenv').config();

class Studio extends Component {
  

  constructor(props){

    super(props);
    
    this.state = {
      debug:0,
      hiddenHeader:0,
      hiddenFiles:0,
      hiddenAssets:0,
      hiddenConsole:0,
      hiddenBarFiles:0,
      description:null,
      defaultCanvasWidth:null,
      defaultCanvasHeigth:null,
      defaultFilesCreate:{},
      hiddenCanvasSize:0,
      getOptions:0,
      mostraResto:0,
      hiddenResizeCanvas:0,
      hiddenTrash:0,
      hiddenCloseTab:0};

    this.resetWorksPace = this.resetWorksPace.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.externalLink = this.externalLink.bind(this);
    this.deleteGame = this.deleteGame.bind(this);

    window.studio = this;

  }

  //para mostar o canvas do phaser
  showCanvas(){ $('.optionHeaderStudio').removeClass('ativo');$('.optionHeaderStudio[type="showCanvas"]').addClass('ativo');$('#divPhaser').fadeIn(0); $("#divAcerEditor").fadeOut(0); }

  //Parar mostrar o javascript do blocklyfile
  showJs(){ 

    $('.optionHeaderStudio').removeClass('ativo'); $('.optionHeaderStudio[type="showJs"]').addClass('ativo'); $('#divPhaser').fadeOut(0); $("#divAcerEditor").fadeIn(0); window.studio_blockly.geraJS(); 

  }

  //para pegar as opções customizadas
  getOptions(){}


  //para resetar o tamanho da workspace
  resetWorksPace(){ var largura = $(window).width(); var b = largura * 0.7; $("#resizeBlockly").css('width',b+'px'); window.studio_blockly.resizeBlockly(); window.studio_blockly.saveSettings();  toast.success('A largura da workspace foi redefinida.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true}); }


  //para gerar os grupos
  returnImportFiles(file,type){

    var assets = [];

    if(file === undefined){ console.error('Você deve informar o parametro file'); return false; }
    if(type === undefined){ type = null; }

    var f = false;


    if(parseInt(type) !== 0 && parseInt(type) !== 1 && parseInt(type) !== 2 && parseInt(type) !== 3 && type !== null){

      console.error('O type deve ser 0,1,2 ou 3'); return false; 

    }

    window.studio_blockly.state.filesList.forEach(function(item,index,array){

      if(parseInt(item.id) === file){
        
        f = true;
        
        item.importAssets.forEach(function(item2,index2,array2){

          if(type === undefined || type === null){ assets.push(item2); }else{
 
            if(parseInt(type) === parseInt(item2.type)){

              assets.push(item2);

            }

          }
           
        });

      }

    });

    if(!f){ console.error('O file informado não foi encontrado.'); return false;  }

    return assets;

  }

  //para pegar todos os arquivos
  returnAllFiles(){

    return window.studio_blockly.state.filesList;

  }

  //para pegar todas as senas
  returnAllScenes(){

    var scenes = [];

    window.studio_blockly.state.filesList.forEach(function(item,index,array){

      if(item.type === "scene"){ 

        scenes.push(item);

      }

    });

    return scenes;

  }


  componentDidUpdate(){

    if(window.studio_blockly !== undefined){

      if(window.studio_blockly.state !== undefined){

        if(window.studio_blockly.state.game.phaserwidth !== undefined){

          var vrindex = window.parseQueryString();
          console.log('REDEFINIU AQUI 1 2 3 ');
          if(vrindex.width !== undefined && vrindex.height !== undefined){
            
            console.log('REDEFINIU AQUI 1 2 3 4 ');

            window.studio_blockly.state.game.phaserwidth = vrindex.width;
            window.studio_blockly.state.game.phaserheigth = vrindex.height;

            window.$("#divIframePhaser").css('width',vrindex.width); 
		        window.$("#divIframePhaser").css('height',vrindex.height);

          }

        }

      }

    }
    
  }

  componentDidMount(){

    $("#loadingTxt").html('Carregando studio');

    //caso esteja informado opcao, busca os dados dela na api
    if(this.props.match.params.options === undefined){

//        console.log('deu undefined');
        this.setState({mostraResto:true});

    }else{

      console.log('buscou');

      api.getStudioOptions(this.props.match.params.options).then((res) => {

          console.log('deu console log res data');
          
          console.log(res.data);

          res.data.mostraResto = true;
          this.setState(res.data);
          
      }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
    
      });
    
    }
  }

  //para delar o game
  deleteGame(){
 
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Depois de excluir é impossível recuperar.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não cancelar'
    }).then((result) => {

      if(result.value){
   
        //remove o arquivo na api
        api.deleteGame(this.props.match.params.game).then((res) => {
          
          toast.success('O projeto foi removido.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true});
          this.props.history.push('/');

        }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);
      
        });

        console.log('removido');

      }else if (result.dismiss === Swal.DismissReason.cancel) {
       

      }

    });

  }


  externalLink(){

    window.open(sessionStorage.getItem('backend')+'/games/generatecode/'+this.props.match.params.game+'/', '_blank');

  }

  async debug(c){


    var game = window.studio_blockly.state.game;
    game.debug = c;

    window.studio_blockly.setState({game:game});

    
    await window.studio_blockly.saveSettings();
    this.setState({a:1});

  }

  openAssetStore(){

    window.$("#assetsStoreModal").modal();
    this.setState({a:'1'});

  }


  render(){

    var Hei = window.innerHeight;
    Hei = Hei - 60;

    return (<>

          
          
          <style>{' body{ overflow: hidden; }  .iframeAsset{ height: '+Hei+'px; } '}</style>

          <link rel='stylesheet' href='/css/studio.css' type='text/css' media='all' />

          { (this.state.hiddenHeader === 1) &&
            <link rel='stylesheet' href='/css/optionStudio/hiddenHeader.css' type='text/css' media='all' />
          }

          { (this.state.hiddenFiles === 1) &&
            <link rel='stylesheet' href='/css/optionStudio/hiddenFiles.css' type='text/css' media='all' />
          }

          { (this.state.hiddenAssets === 1) &&
            <link rel='stylesheet' href='/css/optionStudio/hiddenAssets.css' type='text/css' media='all' />
          }

          { (this.state.hiddenConsole === 1) &&
            <link rel='stylesheet' href='/css/optionStudio/hiddenConsole.css' type='text/css' media='all' />
          }

          { (this.state.hiddenBarFiles === 1) &&
            <link rel='stylesheet' href='/css/optionStudio/hiddenBarFiles.css' type='text/css' media='all' />
          }

          { (this.state.hiddenCanvasSize === 1) &&
            <link rel='stylesheet' href='/css/optionStudio/hiddenCanvasSize.css' type='text/css' media='all' />
          }

          { (this.state.hiddenResizeCanvas === 1) &&
            <link rel='stylesheet' href='/css/optionStudio/resizeCanvas.css' type='text/css' media='all' />
          }

          {
           (this.state.hiddenTrash === 1) &&
            <link rel='stylesheet' href='/css/optionStudio/hiddenTrash.css' type='text/css' media='all' />
          }

          {
           (this.state.hiddenCloseTab === 1) &&
            <link rel='stylesheet' href='/css/optionStudio/hiddenCloseTab.css' type='text/css' media='all' />
          }


          <div id="pageStudio" style={{display:'flex'}}>

            <div className="studio_options">

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Voltar a página inicial</span>}>
              <div className="option option_1 hvr-hang">   <Link to="/"><img alt="" src="/media/home_user_places.png" /></Link> </div>
              </Tooltip>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Assets</span>}>
                <div hidden className="option option_1 hvr-hang" data-target="#assetsModal" data-toggle="modal"> <img alt="" src="/media/1528790.svg" /> </div>
              </Tooltip>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Arquivos</span>}>
                <div hidden className="option option_2 hvr-hang" data-toggle="modal" data-target="#filesModal"> <img alt="" src="/media/148957.svg" /> </div>
              </Tooltip>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Configurações do projeto</span>}>
                <div className="option option_3 hvr-hang" onClick={ () => window.gameConfigurationModal.resetConfiguration() } > <img alt="" src="/media/builder_png_px_website.png" /> </div>
              </Tooltip>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Resetar largura da workspace</span>}>
                <div onClick={ () => this.resetWorksPace() } className="option option_3 hvr-hang"> <img alt="" src="/media/synchronize_sync_refresh.png" /> </div>
              </Tooltip>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Abrir em nova aba</span>}>
                <div onClick={ () => this.externalLink() } className="option option_3 hvr-hang"> <img style={{height:'36px'}} alt="" src="/media/tab_new_add_plus.png" /> </div>
              </Tooltip>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Recompilar</span>}>
                <div onClick={ () => window.studio_blockly.regenerateAllCodes(true) } className="option option_3 hvr-hang"> <img style={{height:'36px'}} alt="" src="/media/package_code.png" /> </div>
              </Tooltip>
 
              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Asset Store</span>}>
                <div onClick={ () => this.openAssetStore() } className="option option_3 hvr-hang"> <img style={{height:'36px'}} alt="" src="/media/rRp1Ezk.png" /> </div>
              </Tooltip>
  
              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Reinciar game</span>}>
                <div onClick={ () => window.phaser.startGame() } className="option option_3 hvr-hang"> <img style={{height:'36px'}} alt="" src="/media/arrow_submit_play_next_right.png" /> </div>
              </Tooltip>

              {

                (this.state.debug === true) &&
                <font className="eae1">
                
                  {

                    (parseInt(window.studio_blockly.state.game.debug) === 1) &&
                    <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Debug on</span>}>
                      <div onClick={ () => this.debug(false) } className="option option_3 hvr-hang "> <img style={{height:'36px'}} alt="" src="/media/debug.png" /> </div>
                    </Tooltip>
     
                  }

                  {

                    (parseInt(window.studio_blockly.state.game.debug) === 0) &&
                    <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Debug off</span>}>
                      <div onClick={ () => this.debug(1) } className="option option_3 hvr-hang debugon"> <img style={{height:'36px',filter:'grayscale(100%)'}} alt="" src="/media/debug.png" /> </div>
                    </Tooltip>
     
                  }

                 </font>


              }
              
               
              <div className="modal fade" id="assetsStoreModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                  <div className="modal-dialog" role="document" style={{maxWidth:'initial'}}>
                      <div className="modal-content">

                        <style>{'.fecharAsset{ cursor:pointer; position: absolute; left: calc(100% - 55px); z-index: 9999; font-size: 29px; top: 62px; color: #fff; }'}</style>
                        <div className="fecharAsset" onClick={ () => window.$('#assetsStoreModal').modal('hide') }><i className="fas fa-times"></i></div>
                        <iframe title="store" className="iframeAsset" src={window.urlstore} frameBorder="0"></iframe>

                      </div>
                  </div>
              </div>
               
              {/*http://enginestore.alunoativo.com.br/categorie/4*/}

              {

              /*

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Baixar projeto</span>}>
                <div className="option option_3 hvr-hang"> <img style={{height:'36px'}} alt="" src="https://findicons.com/files/icons/51/capital_suite/128/download.png" /> </div>
              </Tooltip>
              */

              }

            


           

              {

                /*

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Printar tela do game e salvar como capa</span>}>
                <div className="option option_3 hvr-hang"> <img style={{height:'36px'}} alt="" src="https://findicons.com/files/icons/1786/oxygen_refit/128/image_x_xpixmap.png" /> </div>
              </Tooltip>

                */

              }
              
              <font id="buttonAssociaFile" style={{display:'none'}}>
                <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Associção de arquivo</span>}>
                  <div onClick={ () => window.AssocitionModal.openAssocitionModal() } className="option option_3 hvr-hang"> <img style={{height:'36px'}} alt="" src="/media/seo-web-code-icon.png" /> </div>
                </Tooltip>
              </font>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Deletar projeto</span>}>
                <div onClick={ () => this.deleteGame() } style={{display:'none'}} className="option option_3 hvr-hang"> <img style={{height:'36px'}} alt="" src="/media/trashcan_empty_alt.png" /> </div>
              </Tooltip>
              
            </div>

            <div className="studio_2" style={{display:'flex'}}>

              <div id="divBlockly">

                  { (this.state.mostraResto) &&
                    <Blockly/> 
                  }

              </div>

            </div>
 
            <div style={{backgroundColor: '#282f34',marginLeft:'18px',width:'100%'}}>

              <div className="headerLeftStudio">           
                  <div className="optionHeaderStudio ativo" type="showCanvas" onClick={ () => this.showCanvas() }><font>CANVAS</font></div>
                  <div className="optionHeaderStudio" type="showJs" onClick={ () => this.showJs() }><font>JAVASCRIPT</font> 

                    <font className="fontsize" style={{float:'right',marginRight:'0px',fontWeight:'200'}}>

                    <font id="tamanhoFontAcer">16px</font> <button className="fas fa-plus btfontsize" onClick={ () => window.acereditor.setFontSize(1) }></button> <button className="fas fa-minus btfontsize" onClick={ () => window.acereditor.setFontSize(-1) }></button>


                    </font> 

                  </div>
              </div>
              
              <div>
                <div id="divPhaser">

                    { (this.state.mostraResto) &&
                      <Phaser/> 
                    }
                    
                </div>
                <div id="divAcerEditor" style={{display:'none'}}>

                  { (this.state.mostraResto) &&
                    <AcerEditorViwer/> 
                  }

                </div>
              </div>
   
            </div>

        </div>

        { (this.state.mostraResto) &&
          <>
            <ConfigurationModal/>
            <AssocitionModal/>  
            <AnimationModal/>
            <ImportAssetScene/>
            <UploadAssetModal/>
            <ImportAsseAsset/>
          </>
        }

      </>)


  }


}

export default Studio;