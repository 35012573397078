import React, { Component } from 'react';
import $ from 'jquery';

import { toast } from 'react-toastify';

import api from './../Api';
 
class ImportAsseAsset extends Component {

	constructor(props){ 

      super(props);
      this.state = {SortablePane:true,order:[],file:{},asset:{}};
      this.openModalImportAsseAsset = this.openModalImportAsseAsset.bind(this);

      window.ImportAsseAsset = this;

    }

    openModalImportAsseAsset(id){

    	window.$('#ImportAssetAssetModal').modal();

    	window.$('.checkSelectImportAssetAsset').prop('checked', false);
 
    	this.setState({a:1,editorId:id}); 


    	//pego o asset
    	var asset = window.studio_blockly.state.Assets;
    	var fileid;
    	asset.forEach(function(item,index,array){ 

    		if(parseInt(item.id) === parseInt(id)){ 

	    		this.setState({asset:item}); 
	    		fileid = item.file.id;

    		} 

    	}.bind(this));

    	console.log(fileid);

		var file = window.studio_blockly.state.filesList;
    	setTimeout(function(){

	    	file.forEach(function(item,index,array){

	    		console.log(item.id+' === '+fileid);

	    		if(parseInt(item.id) === parseInt(fileid)){ 
	 
	    			//this.setState({file:item}); 
	    			$.each(item.importAssets, function (index, value) {
	    				
	    				console.log('checkSelectImportAssetAsset[idc='+value.id+']');
						$('.checkSelectImportAssetAsset[idc='+value.id+']').prop('checked', true);
						console.log('checou '+value.id);
						
					});

	    			return true; 

	    		}

	    	});

     	}, 10);

    }

    async salvaImportAsseAsset(){

    	var assets = [];
    	$(".checkSelectImportAssetAsset:checked").each(function(){

    		assets.push($(this).val());

    	});

    	await api.updateFileImportAsset(this.state.asset.file.id,assets).then((res) => {

    		console.log(res.data);

		}).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  
	    });

	    window.studio_blockly.getAllFiles();

		window.$('#ImportAssetAssetModal').modal('hide');
      	toast.success('As alterações foram salvas.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true}); 


  	 	var obj = {};

	    obj.game = window.studio_blockly.state.game.id;
	    obj.user = window.app.state.userId;

	    //para emetir para o resto do pessoal
	    window.emitir('syncScenes',obj);


    }

	render(){

		var assets = {};
		window.defaultAssetsType.map((value,index)=>{

			assets[value.name] = [];
			window.studio_blockly.state.Assets.map((value2,index2) => {

				if(parseInt(value2.id) !== parseInt(this.state.editorId)){ if(parseInt(value2.type) === parseInt(value.id)){ assets[value.name].push(value2); } }
				
				return "";

			});
			
			return "";

		});

		return(<div className="modal fade" id="ImportAssetAssetModal" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" >
				<div className="modal-dialog"  role="document">
				    <div className="modal-content">
				      
				    <br/>
			    	<center><h4>Quais assets o asset "<strong>{this.state.asset.name}</strong>" vai usar? </h4></center>

				      	<div className="modal-body">
					        
					    	{

					    		window.defaultAssetsType.map(function(value,i){

									if(value.id === "3"){ return(<React.Fragment key={value.id}></React.Fragment>); }

							        return(<div key={value.id} className="col-md-12" style={{marginBottom:'12px'}}>

							        		<h5>{value.name3}</h5>
							        		{

							        			(assets[value.name].length === 0) &&
							        			<div className="col-md-12">Nenhum {value.name3} foi encontrado.</div>

							        		}
							        		{
							        			assets[value.name].map(function(value2,i2){

							        				var id = value.name+value.id+value2.id+'nrid';
							        				return( 

							        					<div key={value2.id} className="col-md-12" style={{display:'flex'}}> 
							        					<input className="checkSelectImportAssetAsset" type="checkbox" id={id} idc={value2.id} value={value2.id} /> 
							        					<label style={{lineHeight:'11px',marginLeft:'5px'}} htmlFor={id}>{value2.name}</label>
							        					</div> )

							        			})

							        		}


						        	</div>)
							        
						      	})

					    	}

				      	</div> 

				      	<div className="modal-footer">

					      	<button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
					      	<button type="submit" className="btn btn-primary" onClick={ () => this.salvaImportAsseAsset()} >Salvar</button>

				      	</div>

				    </div>
				  </div>
			  	</div>) 

	}


}

export default ImportAsseAsset;