import React, { Component } from 'react';
import api from './../Api';
import $ from 'jquery';
import { SortablePane, Pane } from 'react-sortable-pane';

require('dotenv').config();

class AnimationModal extends Component {

	constructor(props){

    	super(props);
    	
    	this.state = {asset:null,animations:[],loadAnimation:false,order:[],reasset:false,viewAnim:null}
    	this.openAnimationModal = this.openAnimationModal.bind(this);
    	this.removeAnimation = this.removeAnimation.bind(this);

    	window.animationModal = this;

	}

	componentDidMount(){}

	openAnimationModal(id){

		window.$("#animationModal").modal();
		var asset;
		 
		window.studio_blockly.state.Assets.forEach(function(item,index,array){

			if(parseInt(item.id) === parseInt(id)){ asset = item; }

		});

		console.log('setou');
		console.log(asset);

		this.setState({asset:asset,loadAnimation:true,showPane:false,viewAnim:null});
		this.getAnimation(asset.id);

	}

	getAnimation(id){

		this.setState({showPane:false});

		api.getAnimations(id).then((res) => {
 			
			var order = [];
			res.data.forEach(function (item,index,array){ order.push(item.id); });

			this.setState({loadAnimation:true,animations:res.data,order:order,showPane:true,reasset:true});
			console.log(res.data);

	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  
	    });

	}

	newAnimation(){
		
		var pass = true;

		//Verifica se tem alguma com nome em branco
		$(".animacao").each(function(){

			var val = $(this).find('.key_name').val();
			if(val === ""){
 
				$(this).find('.key_name').css('border','1px solid #F44336');
				$(this).find('.key_name').removeClass('tada');

				setTimeout(function(){
					
					$(this).find('.key_name').addClass('animated');
					$(this).find('.key_name').addClass('tada');

				}.bind(this), 100);
			
				pass = false;

			}else{ 
				
				$(this).find('.key_name').css('border','1px solid #dee2e6');
		
			}

		});

		if(pass === false){ return false; }

		api.newAnimation({asset:this.state.asset.id,name:'',start:'0',end:'0',frameRate:'10',repetition:'-1'}).then((res) => {
 			
 			var dados = this.state.animations;
 			dados.push(res.data);
 			this.setState({animations:dados});
 			window.studio_blockly.getAllAssets();
		 
	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  
	    });

	}

	removeAnimation(id){

		api.removeAnimation(id).then((res) => {
 	
 			this.getAnimation(this.state.asset.id);
 			window.studio_blockly.getAllAssets();

	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  
	    });

	}


	updateAnimation(id,value,colum){

		var dados = {};
		dados[colum] = value;

		api.updateAnimation(id,dados).then((res) => {
 		
 			console.log(res.data);
 			window.studio_blockly.getAllAssets();
 
	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  
	    });

	}

	updateOrder(){

		var c = 0;
		this.state.order.forEach(function (item, index, array){

			api.updateAnimation(item,{sequence:c}).then((res) => {
	 		
	 			console.log(res.data);

		    }).catch(error => {

		        console.log(error.data);
		        console.log(error);
		        console.log(error.response);
		  
		    });

		});
  
	}


	updateAsset(){

		console.log('deu update');

		api.updateAsset(this.state.asset.id,{frameWidth:this.state.asset.frameWidth,frameHeight:this.state.asset.frameHeight}).then((res) => {
 			 
 			this.setState({asset:res.data});

			var asset = window.studio_blockly.state.Assets;
			window.studio_blockly.state.Assets.forEach(function(item,index,array){

				if(parseInt(item.id) === parseInt(res.id)){ 

					asset[index] = res.id;

				}

			});

			window.studio_blockly.setState({Assets:asset});
  

	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  
	    }); 
  
	}

	 

	viewAnimation(id){

		if(id === "close"){ this.setState({viewAnim:false}); return false;}

		this.setState({viewAnim:id,iframeUrl:sessionStorage.getItem('backend')+'/assets/generatespritesheet/'+id});

		$("#iframeViewAnimation").attr('src');
		var link = $("#iframeViewAnimation").attr('src');
		$("#iframeViewAnimation").attr('src',link);

	}

	async changeVal(name,val){

		var asset = this.state.asset;
		asset[name] = val;
		
		val = parseInt(val);

		await this.setState({asset});

		this.updateAsset();

	}

	render(){


		var height = 0; height = 80 * this.state.animations.length;

 		if(this.state.asset !== null){

			var styleIframe = {width:this.state.asset.frameWidth,height:this.state.asset.frameHeight,background: 'rgb(255, 255, 255)',border:'none',boxShadow:'rgb(0, 0, 0) 0px 0px 5px -4px'};
 

		}
		


		return(<div className="modal fade" id="animationModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
			  <div className="modal-dialog" role="document" style={{maxWidth:'760px'}}>
			    <div className="modal-content">
			 		
			 		<br/>
			 		<center><h3>Animações de 

			 		{ 

			 			(this.state.asset !== null) &&
			 			<strong> {this.state.asset.name}</strong>

			 		}

			 		</h3></center>
					<center>Informe abaixo a largura e altura do frame.</center>

					 	<br/>

					 	{  (this.state.asset !== null && this.state.reasset) &&
						
							<div className="col-md-12">

								<div className="row mx-auto">
								<div className="col-md-3 offset-md-3">FrameWidth: <input id="frameWidth" onChange={ (event) => { this.changeVal('frameWidth',event.target.value); }} className="form-control" value={this.state.asset.frameWidth} /> </div>
								<div className="col-md-3">FrameHeight: <input id="frameHeight" onChange={ (event) => { this.changeVal('frameHeight',event.target.value); }} className="form-control" value={this.state.asset.frameHeight} /> </div>
								</div>

							</div>

						}

					 

					<br/>

					 

					{

						(this.state.viewAnim !== null) &&

							<div>
								<center><font style={{cursor:'pointer'}} onClick={ () => this.setState({viewAnim:null}) } >Close preview</font></center>
								<center><iframe id="iframeViewAnimation" src={this.state.iframeUrl} style={styleIframe} title="nanimacao"></iframe></center>
							</div>
 

					}
					 

					<center><h4>Lista de animações</h4></center>

					{
						(this.state.animations.length === 0)&&
						<center><h5>Nenhuma animação foi encontrada.</h5></center>

					}

					<div style={{height:height+'px'}} >

						{ (this.state.showPane) && 

							<SortablePane resizable={{x:false,y:false,xy:false}}  dragHandleClassName="movedor" direction="vertical" margin={5} onOrderChange={order => { this.setState({ order }); }} onDragStop={order => { this.updateOrder(); }}  >
		              
							{

								this.state.animations.map((value,index) => {

									 
									return(

									<Pane resizable={{x:false,y:false,xy:false}} className="__resizable_base__" key={value.id} defaultSize={{ width: '100%',height:'80px'}} minHeight="80px">

										<div idc={value.id} key={value.id} className="col-md-12 animacao" idc={value.id} style={{marginTop:'8px'}}>
											<div className="row">
												<center style={{display:'flex'}} >

												 
													<div className="col-md-2" style={{display:'flexs',marginLeft:'10px',paddingLeft:'25px'}} ><i style={{position:'absolute',left:'0px',top:'34px'}} class="fas fa-arrows-alt movedor"></i> Key: <input defaultValue={value.name} onKeyUp={ (event) => this.updateAnimation(value.id,event.target.value,'name') } style={{textAlign:'center'}} type="text" className="form-control key_name" />  </div>
													<div className="col-md-2" style={{display:'flexs'}} >Start: <input defaultValue={value.start} onKeyUp={ (event) => this.updateAnimation(value.id,event.target.value,'start') } style={{textAlign:'center'}} type="text" className="form-control" />  </div>
													<div className="col-md-2" style={{display:'flexs'}} >End: <input defaultValue={value.end} onKeyUp={ (event) => this.updateAnimation(value.id,event.target.value,'end') } style={{textAlign:'center'}} type="text" className="form-control" />  </div>
													<div className="col-md-2" style={{display:'flexs'}} >Frame rate: <input defaultValue={value.frameRate} onKeyUp={ (event) => this.updateAnimation(value.id,event.target.value,'frameRate') } style={{textAlign:'center'}} type="text" className="form-control" />  </div>
													<div className="col-md-2" style={{display:'flexs'}} >Repeat: <input defaultValue={value.repetition} onKeyUp={ (event) => this.updateAnimation(value.id,event.target.value,'repetition') } style={{textAlign:'center'}} type="text" className="form-control" />  </div>
													
													<div className="col-md-2" style={{display:'flexs',paddingTop:'25px',paddingLeft:'1px'}} > 

														<button className="btn btn-primary btn-sm" style={{marginRight:'3px'}} onClick={ () => this.viewAnimation(value.id) }> <i class="far fa-eye"></i> </button>
														<button className="btn btn-danger btn-sm" onClick={ () => this.removeAnimation(value.id) }><i class="far fa-trash-alt"></i></button>  

													</div>
												</center>
											</div>
										</div>

									</Pane>)

								})

							}

					 		</SortablePane>

				 		}

					</div>
					 

					<br/>
				 

			      	<center style={{marginBottom:'10px',marginTop:'10px'}}>

			        	<button type="button" className="btn btn-secondary" data-dismiss="modal">Fechar</button> 
			        	<button onClick={ () => this.newAnimation() } type="button" className="btn btn-primary" style={{marginLeft: '5px'}}><i className="fas fa-plus"></i> Nova animação </button>
			        
			        </center>

			    </div>
			  </div>
			</div>)

	}


}

export default AnimationModal;