import React, { Component } from 'react';
import $ from 'jquery';
import api from './../Api';

//import './assets/login-v3.default.css';
//import './assets/vendors.bundle.css';
//import api from './../Api';
import { toast } from 'react-toastify';
//import Sortable from 'sortablejs';

//import Swal from 'sweetalert2';

//import Login from './Login';
//import Head from './Head';

 
class ImportAssetScene extends Component {

	constructor(props){ 

      super(props);
      this.state = {SortablePane:true,order:[],file:{}};
      this.openModalImportAssetScene = this.openModalImportAssetScene.bind(this);

      window.importassetscene = this;

    }

    openModalImportAssetScene(id){

    	window.$('#ImportAssetSceneModal').modal();

    	window.$('.checkSelectImport').prop('checked', false);

    	var file = window.studio_blockly.state.filesList;
    	this.setState({a:1}); 

    	setTimeout(function(){

	    	file.forEach(function(item,index,array){

	    		if(parseInt(item.id) === parseInt(id)){ 
	 
	    			this.setState({file:item}); 
	    			$.each(item.importAssets, function (index, value) {
					  $('.checkSelectImport[idc='+value.id+']').prop('checked', true);
					  console.log('checou '+value.id);
					});

	    			return true; 

	    		}

	    	}.bind(this));

     	}.bind(this), 10);

    }

    async salvaImportAssets(){

    	var assets = [];
    	$(".checkSelectImport:checked").each(function(){

    		assets.push($(this).val());

    	});

     
    	await api.updateFileImportAsset(this.state.file.id,assets).then((res) => {

    		console.log(res.data);

		}).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  
	    });

	    window.studio_blockly.getAllFiles();


	    var obj = {};

	    obj.game = window.studio_blockly.state.game.id;
	    obj.user = window.app.state.userId;

	    //para emetir para o resto do pessoal
	    window.emitir('syncScenes',obj);








		window.$('#ImportAssetSceneModal').modal('hide');
      	toast.success('As alterações foram salvas.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true}); 


    }

	render(){

		var assets = {};
		window.defaultAssetsType.map((value,index)=>{

			assets[value.name] = [];
			window.studio_blockly.state.Assets.map((value2,index2) => {

			  if(parseInt(value2.type) === parseInt(value.id)){ assets[value.name].push(value2); }
			  return "";

			});
			
			return "";

		});

		return(<div className="modal fade" id="ImportAssetSceneModal" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" >
				<div className="modal-dialog"  role="document">
				    <div className="modal-content">
				      
				    <br/>
			    	<center><h4>Quais assets a scene "<strong>{this.state.file.name}</strong>" vai usar? </h4></center>

				      	<div className="modal-body">
					        
					    	{

					    		window.defaultAssetsType.map(function(value,i){
									
									if(value.id === "3"){ return(<React.Fragment key={value.id}></React.Fragment>); }
									
							        return(<div key={value.id} className="col-md-12" style={{marginBottom:'12px'}}>

							        		<h5>{value.name3}</h5>
							        		{

							        			(assets[value.name].length === 0) &&
							        			<div className="col-md-12">Nenhum {value.name3} foi encontrado.</div>

							        		}
							        		{
							        			assets[value.name].map(function(value2,i2){

							        				var id = value.name+value.id+value2.id;
							        				return( 

							        					<div key={value2.id} className="col-md-12" style={{display:'flex'}}> 
							        					<input className="checkSelectImport" type="checkbox" id={id} idc={value2.id} value={value2.id} /> 
							        					<label style={{lineHeight:'11px',marginLeft:'5px'}} htmlFor={id}>{value2.name}</label>
							        					</div> )

							        			})

							        		}


						        	</div>)
							        
						      	})

					    	}

				      	</div> 

				      	<div className="modal-footer">

					      	<button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
					      	<button type="submit" className="btn btn-primary" onClick={ () => this.salvaImportAssets()} >Salvar</button>

				      	</div>

				    </div>
				  </div>
			  	</div>) 

	}


}

export default ImportAssetScene;