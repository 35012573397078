import React, { Component } from 'react';
import $ from 'jquery';

//import './assets/login-v3.default.css';
//import './assets/vendors.bundle.css';
import api from './../Api';
//import Sortable from 'sortablejs';

import moment from 'moment'; 

//import Login from './Login';
//import Head from './Head';

 
 
class ListaProjetos extends Component {

	 

	acessarGame(id){
	 
	    window.app.loading(true);
	    setTimeout(function(){ window.location.href = '/studio/'+id;  }, 500);  

  	} 
 
  

  cadastraGame(event){
 
    event.preventDefault();
    var dados = {"name": $("#exampleInputEmail11").val(),
    "dateCreate":moment().format("YYYY-MM-DD h:mm:ss"),
    "user": sessionStorage.getItem('userId'),
    "toolBoxSelected":"0",
    phaserwidth:$("#exampleInputEmail44").val(),
    phaserheigth:$("#exampleInputEmail33").val(),
    fontSize:16,
    aceEditorFontSize:16,
    widthcanvas:9999,
    defaultEditor:0,
    physic:$("#exampleInputEmail55").val(),
    description:$("#exampleInputEmail22").val(),
    assetAbaDefault:'0',
    advanced:false,
    debug:false};
  
    api.newGames(dados).then((res) => {

      console.log(res);
      window.$('div').modal('hide');
      setTimeout(function(){ this.acessarGame(res.data.id); }.bind(this), 200);
      
    }).catch(error => {

      console.log(error.data);
      console.log(error);
      console.log(error.response);

    });

  }


	render(){

		return(<div className="modal fade" id="novoProjetoModal" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" >
				<div className="modal-dialog"  role="document">
				    <div className="modal-content">
				      
				    <br/>
			    	<center><h3>Novo projeto?</h3></center>

				      	<div className="modal-body">
					        
					        <form id="formCadastroGame" onSubmit={ (event) => this.cadastraGame(event) }>
		                    
			                    <div className="form-group">
			                      <label htmlFor="exampleInputEmail1">Nome do projeto:</label>
			                      <input required type="text" name="name" className="form-control" id="exampleInputEmail11" aria-describedby="emailHelp" /> 
			                    </div>

			                    <div className="form-group" hidden>
									<label >Editor padrão:</label>

									<select id="defaultEditor2" className="form-control">

										{ 	

											window.defaultEditorOptions.map(function(item, i){
											  
											  return <option key={i} value={i} >{item}</option>

											})

										}

									</select>
								</div>

			                    <div className="form-group">
			                      <label htmlFor="exampleInputEmail2">Descrição:</label>
			                      <textarea type="text" name="name" className="form-control" id="exampleInputEmail22" aria-describedby="emailHelp"></textarea>
			                    </div>

			                    <div className="row">

			                    	<div className="col-md-4">
			                    		<div className="form-group">
					                      <label htmlFor="exampleInputEmail3">Altura:</label>
					                      <input required defaultValue="300" type="text" name="name" className="form-control" id="exampleInputEmail33" aria-describedby="emailHelp" /> 
					                    </div>
			                    	</div>

			                    	<div className="col-md-4">
				                    	<div className="form-group">
					                      <label htmlFor="exampleInputEmail4">Largura:</label>
					                      <input required defaultValue="250" type="text" name="name" className="form-control" id="exampleInputEmail44" aria-describedby="emailHelp" /> 
					                    </div>
				                    </div>

			                    	<div className="col-md-4">
				                    	<div className="form-group">
					                      <label htmlFor="exampleInputEmail5">Tipo de física:</label>
					                      <select type="text" name="name" required="" className="form-control" id="exampleInputEmail55" aria-describedby="emailHelp">

					                      	<option value="arcade">Arcade</option>

					                      </select>
					                    </div>
				                    </div>

			                    </div>

			                    <button type="submit" className="btn btn-primary" style={{width:'100%'}} >Cadastrar projeto <i className="fas fa-plus"></i></button>

			                </form>

				      	</div> 

				    </div>
				  </div>
			  	</div>) 

	}


}

export default ListaProjetos;