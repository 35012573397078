 
import React, { Component } from 'react';
import $ from 'jquery';
import {Link} from 'react-router-dom';


//import './assets/login-v3.default.css';
//import './assets/vendors.bundle.css';
import api from './../Api';
//import Sortable from 'sortablejs';

import Swal from 'sweetalert2';

//import Files from './../modal/Files';
import TableFiles from './../table/Files';

//import Assets from './../modal/Assets';
import arrayMove from 'array-move';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import Login from './Login';
//import Head from './Head';

import Resizable from 're-resizable'; 
import AceEditorMaster from './AceEditorMaster';
import JsonEditor from "./JsonEditor";
import ClipLoader from "react-spinners/ClipLoader"; 
 


function toTitle(string, separator = ' ') {
	if(string === ""){ return ''; }
	return string.split(' ').map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase()).join(' ')
}


function corrigeCodigo(file,javascript){



//	console.log(file);
	//console.log(javascript);

	if(file === undefined || file === null){ return javascript; }
	//console.log(file);

	//caso seja um arquivo to tipo sena
	if(file.type === "scene"){


			//console.log('é uma sena');

			//pega os assets importados
			var imports_txt = "";

			var imports = window.studio.returnImportFiles(file.id,0);
			var imports2 = window.studio.returnImportFiles(file.id,1);

			imports.forEach(function(item,index,array){

				imports_txt = imports_txt + "import "+toTitle(item.name)+" from '../sprites/"+item.name.toLowerCase()+".js';";

			});

			imports2.forEach(function(item,index,array){

				imports_txt = imports_txt + "import "+toTitle(item.name)+" from '../sprites/"+item.name.toLowerCase()+".js';";

			});

			if(imports_txt !== ""){ imports_txt = imports_txt + '\n\n'; }

    		javascript = imports_txt+'class '+toTitle(file.name)+' extends Phaser.Scene { \n\n'+javascript+'\n}\n\nexport default '+toTitle(file.name)+';';

	}

	//caso seja um arquivo do tipo asset
	if(file.type === "asset"){

		//console.log('é um asset');
		var asset = undefined;

		//pego o asset
		window.studio_blockly.state.Assets.forEach(function(item,index,array){

			if(file.asset !== undefined){

//				console.log(file.asset.id+' === '+item.id);
				if(file.asset.id === item.id){ asset = item; }

			}

		});

		var importAssets = "";
		//console.log(file);
		//console.log(asset);

		file.importAssets.forEach(function(item,index,array){

			importAssets = importAssets + "import "+toTitle(item.name)+" from '../sprites/"+item.name.toLowerCase()+".js'; \n";

		});
		
		//console.log(file.importAssets);

		if(importAssets !== ""){ importAssets = importAssets+'\n'; }

		if(asset !== undefined){

			//console.log(asset);

			//caso seja imagem
			if(parseInt(asset.type) === 0){

				javascript = importAssets+'export default class '+toTitle(asset.name)+' extends Phaser.GameObjects.Sprite {\n					\n					'+javascript+'\n					\n				}';

			}

			//caso seja SpriteSheet
			if(parseInt(asset.type) === 1){

				javascript = importAssets+'export default class '+toTitle(asset.name)+' extends Phaser.GameObjects.Sprite {\n					\n					'+javascript+'\n\n}';

			}

		}
 
	} 

    return javascript;

}

require('dotenv').config();
 
class Blockly extends Component {

	constructor(props){ 
    	super(props); 
 
		this.state = {
			game:{},
			blocklyReady:false,
			blocklyReady2:false,
			xml:'',
			javascript:'',
			toolBoxList:[],
			filesList:[],
			dadosGame:{},
			getXmlBlocks:false,
			getBlocksJs:false,
			getFiles:false,
			getAllAssets:false,
			Assets:[],
			orderFiles:[],
			loadgetBlocksJs:true,
			loadgetAllFiles:true,
			loadgetToolBox:true,
			defaultAceEditorValue:"",
			aceEditorDivEventClick:false,
			log:false,
			loadingFile:true,
			defaultstartcode:false,getDefaultCodes:false};

		this.salvaXML = this.salvaXML.bind(this);
		this.geraXML = this.geraXML.bind(this);
		this.geraJS = this.geraJS.bind(this);
		this.carregaDados = this.carregaDados.bind(this);
		this.carregaListaToolBox = this.carregaListaToolBox.bind(this);
		this.getToolBox = this.getToolBox.bind(this);
		this.preGetToolBox = this.preGetToolBox.bind(this);
		this.getAllJsBlocks = this.getAllJsBlocks.bind(this);
		this.saveSettings = this.saveSettings.bind(this);
		this.loadBlock = this.loadBlock.bind(this);
		this.preGetFile = this.preGetFile.bind(this);
		this.updateWorkspace = this.updateWorkspace.bind(this);
		this.createFile = this.createFile.bind(this);
		this.removeFile = this.removeFile.bind(this);
		this.updateFile = this.updateFile.bind(this);
		this.checkBoxStatus = this.checkBoxStatus.bind(this);
		this.updateNameFile = this.updateNameFile.bind(this);
		this.updateNameAsset = this.updateNameAsset.bind(this);
		this.sendAsset = this.sendAsset.bind(this);
		this.newAsset = this.newAsset.bind(this);
		this.getAllAssets = this.getAllAssets.bind(this);
		this.removeAsset = this.removeAsset.bind(this);
		this.renderTableAssets = this.renderTableAssets.bind(this);
		this.updateAsset = this.updateAsset.bind(this); 
		this.ocultarFile = this.ocultarFile.bind(this); 
		this.desocultarFile = this.desocultarFile.bind(this);
		this.selectFirstFile = this.selectFirstFile.bind(this);
		this.defaultCanvasSize = this.defaultCanvasSize.bind(this);
		this.removeFileApi = this.removeFileApi.bind(this);

		this.getFileState = this.getFileState.bind(this);
		this.getAssetState = this.getAssetState.bind(this);
		this.removeAssetApi = this.removeAssetApi.bind(this);
		this.getDefaultstartcode = this.getDefaultstartcode.bind(this);
		this.regenerateAllCodes = this.regenerateAllCodes.bind(this);
		this.carregaTudoV1 = this.carregaTudoV1.bind(this);
		this.carregaTudoV2 = this.carregaTudoV2.bind(this);
		this.openFileAndShowJs = this.openFileAndShowJs.bind(this);
		this.getAnimationsFileAtual = this.getAnimationsFileAtual.bind(this);

		//this.aceEditorMasterChange = this.aceEditorMasterChange.bind(this);
		//this.restauraCodigoAceEditor = this.restauraCodigoAceEditor.bind(this);
		window.studio_blockly = this; 

	}

	///quando o sorte de FILE termina
	onSortFileEnd = ({oldIndex, newIndex}) => {

	    this.setState(({filesList}) => ({filesList: arrayMove(filesList, oldIndex, newIndex)}));

	    var ids = [];
	    this.state.filesList.forEach(function (item, index, array) { ids.push(item.id.toString()); });

	    window.files_a.setState({order:ids});
	    window.phaser.setState({a:1});

	    //this.updateSequence();
	    $('.tobd_n').fadeIn(0);

	};

	//quando o sort de asset finaliza
	onSortAssetEnd = ({oldIndex, newIndex}) => {

	    this.setState(({Assets}) => ({Assets: arrayMove(Assets, oldIndex, newIndex)}));
	    window.phaser.setState({a:1});

	};

	//para pegar os dados do state sem precisar ir até o servidor.
	getFileState(id){

		var file = false;
		this.state.filesList.forEach(function(item, index, array){

			if(parseInt(item.id) === parseInt(id)){ file = item; }

		});

		return file;
	}



	//Para pegar as animações do arquivo atual
	getAnimationsFileAtual(){

		var file = this.state.game.fileSelected;
		file = this.getFileState(file);

		//verifico se tem asset
		if(file.type === "asset"){

			var asset = file.asset;
			var assetReal = this.getAssetState(asset.id);
		 
			if(assetReal !== undefined){ return assetReal; }

		}

		return [];

	}


	//usado la no debuglog

	openFileAndShowJs(fileNameMin){

		var files = this.state.filesList;
		files.forEach(function(item,index,array){

			if(item.namemin === fileNameMin){

				this.preGetFile(item.id);
				$('.optionHeaderStudio').click();

			}
			

		}.bind(this));

	}



	//função para gerar o codigo js de todos os arquivos
	async regenerateAllCodes(alerta){

		console.log('regenerou todos os codigos');

		 var files = this.state.filesList;

		 var stateAtual = this.state.game.fileSelected;

			 for (var i = 0; i < files.length; i++) {

			 	//Caso seja JSON ele ignora
	 			if(files[i].type === "json"){}else{

		 			this.state.game.fileSelected = files[i].id;

		 			var xml = window.Blockly.Xml.textToDom(files[i].blocklyxml);

					var wr = new window.Blockly.Workspace();
					window.Blockly.Xml.domToWorkspace(xml, wr);
					window.Blockly.JavaScript.INFINITE_LOOP_TRAP = null;
				 		
					var javascript = window.Blockly.JavaScript.workspaceToCode(wr);
					var code2 = corrigeCodigo(files[i],javascript);

					code2 = window.js_beautify(code2);

					this.state.filesList[i].javascript = code2;


					await api.updateFile(this.state.filesList[i].id,{javascript:code2}).then((res) => {


				    }).catch(error => {

				        console.log(error.data);
				        console.log(error);
				        console.log(error.response);
				  
				    });

				}

		 	}


		//volta ao estado anterior 
		var game = this.state.game;
		game.fileSelected = stateAtual;
		this.setState({game:game});

		console.log('terminou');
		if(alerta === true){ 

			toast.success('O código do jogo foi recompilado.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true}); 
			
		}


	}


	async getDefaultstartcode(){
 
		var getDefaultstartcode = await api.getDefaultstartcode({}).then((res) => {

  	 		//if(this.state.log)
//        	console.log(res.data);
        	this.setState({defaultstartcode:res.data})
        	return true;

	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  		return false;

	    });

	    return getDefaultstartcode;

	}

	//para pegar os dados do state sem precisar ir até o servidor.
	getAssetState(id){

		var asset = false;
		this.state.Assets.forEach(function(item, index, array){

			if(parseInt(item.id) === parseInt(id)){ asset = item; }

		});

		return asset;
	}

	//para resetar o local de files da esquerda
	resetFileEdit(){

		setTimeout(function(){ window.files_a.setState({SortablePane:false});  }, 0);
	  	setTimeout(function(){ window.files_a.setState({SortablePane:true}); }, 100);

	}
  	
  	//para remover um arquivo informado o ID
	removeFile(id){

		if($('.myTab').length === 1){ 

			toast.error('Você deve ter no mínimo um arquivo na workspace.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true}); 
			return false; 

		}

		Swal.fire({
		  title: 'Você tem certeza?',
		  text: 'Depois de excluir o arquivo, é impossível recuperar.',
		  type: 'warning',
		  showCancelButton: true,
		  confirmButtonText: 'Sim',
		  cancelButtonText: 'Não cancelar'
		}).then((result) => {

		  if(result.value){

		  	 this.removeFileApi(id);

		  }else if (result.dismiss === Swal.DismissReason.cancel) {
		   

		  }

		});

	}


	async removeFileApi(id){

			var order = [];
        	var filesList = [];

		  	this.state.filesList.forEach(function(item, index, array){ if(parseInt(id) !== item.id){ order.push(item.id.toString()); filesList.push(item);  } });

		  	this.setState({filesList:filesList});

		  	window.files_a.setState({SortablePane:false});
		  	window.files_a.setState({order:order});
		  	setTimeout(function(){ window.files_a.setState({SortablePane:true}); }, 100);
		  	
		  	//para resetar o organizador de files da esquerda
		  	//this.resetFileEdit();

		  	//caso o file que eu estou removendo... seja o que esteja aberto... eu ja seleciono o primeiro
		  	if(parseInt(id) === parseInt(this.state.game.fileSelected)){

		  		if(this.state.log)
	  			console.log('selectionou o primeiro');

		  		this.selectFirstFile();

		  	}
 
		  	//remove o arquivo na api
	  	 	var a = await api.removeFile(id).then((res) => {

	  	 		if(this.state.log)
	        	console.log(res);


	         	var obj = {};

			    obj.game = window.studio_blockly.state.game.id;
			    obj.user = window.app.state.userId;
			    obj.reset = 1;

			    //para emetir para o resto do pessoal
			    window.emitir('syncScenes',obj);
			    return true;
	        
		    }).catch(error => {

		        console.log(error.data);
		        console.log(error);
		        console.log(error.response);
		  		return false;

		    });

		    return a;

	}


	//Para criar um arquivo informando o nome
	newFileByName(){

		Swal.fire({
		  title: 'Informe um nome',
		  input: 'text',
		  inputAttributes: {
		    autocapitalize: 'off'
		  },
		  showCancelButton: true,
		  confirmButtonText: 'Criar',
		  cancelButtonText:  'Cancelar',
		  showLoaderOnConfirm: true,
		  preConfirm: (name) => {

		  	if(name === ""){ Swal.showValidationMessage('Não pode ficar em branco.'); return false; }

		  	var blocklyxml = '<xml xmlns="http://www.w3.org/1999/xhtml"></xml>';

		  	//verifica se tem o código inicial
	  		this.state.defaultstartcode.forEach(function(item,index,array){

	  			if(parseInt(item.editor) === 0 && parseInt(item.type) === 999){

	  				blocklyxml = item.code;

	  			}

	  		});


		  	this.createFile(name,'scene','',blocklyxml);

		    return true;
 
		  },
		  allowOutsideClick: () => !Swal.isLoading()
		}).then((result) => {})

	}

	//para criar o arquivo no banco de dados
	async createFile(name,type,javascriptAceEditor,blocklyxml){

		var namemin = name.replace(/ /g,'');
		namemin = namemin.toLowerCase()+'.js';

		if(javascriptAceEditor === undefined){ javascriptAceEditor = ""; }
		if(blocklyxml === undefined){ blocklyxml = '<xml xmlns="http://www.w3.org/1999/xhtml"></xml>'; }

		var res = await api.createFile({type:type,game:window.studio.props.match.params.game,javascriptAceEditor:javascriptAceEditor,positionAceEditor:"{\"row\": 0,\"column\": 0}",name:name,editor:this.state.game.defaultEditor,status:true,namemin:namemin,blocklyxml:blocklyxml}).then((res) => {
 
			var filesList = this.state.filesList;
			filesList.push(res.data);

			var order = window.files_a.state.order;
			order.push(res.data.id.toString());

			this.setState({filesList:filesList});
 			window.files_a.setState({order:order});

 			return res.data;

	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	 		return false;

	    })


	    var obj = {};

	    obj.game = window.studio_blockly.state.game.id;
	    obj.user = window.app.state.userId;

	    //para emetir para o resto do pessoal
	    window.emitir('syncScenes',obj);

	    this.regenerateAllCodes();

	    return res;

	}


	//gera o xml do blockly
	geraXML(){

		var xml = window.Blockly.Xml.workspaceToDom(window.demoWorkspace);
      	var xml_text = window.Blockly.Xml.domToText(xml);

      	if(this.state.log)
      	console.log(xml_text);

      	return xml_text;

	}

	//para gerar o javascript do arquivo
	geraJS(file){

		window.Blockly.JavaScript.INFINITE_LOOP_TRAP = null;
    	var javascript = window.Blockly.JavaScript.workspaceToCode(window.demoWorkspace);

    	//verifico se o codigo que esta sendo gerado pertence a uma scene
    	file = this.state.game.fileSelected;
    	this.state.filesList.forEach(function (item,index,array){ if(parseInt(item.id) === parseInt(file)){ file = item; } });



    	//para puxar o codigo corrigido
    	javascript = corrigeCodigo(file,javascript);
 
    	//pego o codigo gerado e idento ele
    	javascript = window.js_beautify(javascript,2);
    	window.acereditor.setValue(javascript);

      	return javascript;

	}

	//restaura o codigo do blockly
	restaura_codigo(xml){

		//joga no aceeditor
		if(this.state.log)
		console.log('restaura_codigo');

    	window.demoWorkspace.clear();
    	xml = window.Blockly.Xml.textToDom(xml);
    	window.Blockly.Xml.domToWorkspace(xml, window.demoWorkspace);
    	this.geraJS(); 

    	window.noSave = true;
    	setTimeout(function(){ window.noSave = false; }, 200);

	}

	//para salvar o xml no banco
	async salvaXML(event){

		//console.log(event);
		//console.log(event.type);
		if(this.state.loadingFile === true){ return false; }

 		if(event.type !== "change" && event.type !== "move" && event.type !== "delete" && event.type !== "create"){ return false; }

		if(window.noSave === true){ return false; }

		if(this.state.log)
		console.log('salvou arquivo');

		var xml = this.geraXML();
		var js = this.geraJS();

//		console.log('salvou xml');
		window.emitMyBlockly();

		//para salvar no novo state sem dar render
		this.state.filesList.forEach(function(item,index,array){
			if(parseInt(item.id) === parseInt(this.state.game.fileSelected)){
				item.blocklyxml = xml;
				item.javascript = js;
				this.state.filesList[index] = item;
			}
		}.bind(this));


		console.log('Salvou xml do bloco '+$("#fileselected").val());

		await api.updateFile($("#fileselected").val(),{blocklyxml:xml,javascript:js}).then((res) => {

			if(this.state.log)
        	console.log(res);
        
	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  
	    });


	    //this.regenerateAllCodes();

	}

	//para pegar os dados do game
	async carregaDados(ignoreToolBox){

		$("#loadingTxt").html('Carregando dados do projeto.');

		var carregar = await api.getGame(window.studio.props.match.params.game).then(async (res) => {

			this.setState({game:res.data});

			window.studio.setState({debug:true});

			if(res.data.advanced){ $("#buttonAssociaFile").fadeIn(0); }
			console.log('tamanho da fonte: '+res.data.fontSize);


			$("#divIframePhaser").css('width', res.data.phaserwidth+'px');
			$("#divIframePhaser").css('height', res.data.phaserheigth+'px');

			//para o phaser saber que autalizou o blockly
			//window.phaser.setState({update:1});

			//caso ignoreToolBox == 1, ele não carrega o toolbox
			if(ignoreToolBox !== 1){

				await this.carregaListaToolBox();	

			}

			return true;
			
	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);

	        if(error.response !== undefined){

	        	if(error.response.status !== undefined){

	        		if(error.response.status === 404){ $("#loadingTxt").html('O Projeto não foi encontrado.'); $("#page-loader").find('.loader-icon').fadeOut(0); }
	        		if(error.response.status === 401){ $("#loadingTxt").html('Você não esta logado na plafatorma.'); $("#page-loader").find('.loader-icon').fadeOut(0); }
	        		if(error.response.status === 403){ $("#loadingTxt").html(error.response.data.msg); $("#page-loader").find('.loader-icon').fadeOut(0); }

	        	}

	        }

	        return false;
	  
	    });

	    return carregar;

	}


	//para salvar a nova sequencia do files
	updateSequence(ignoreput,igrnoreStateOrder){

		var c = 0;
		var ids = [];
		$('.rdTabWrapper').find('li').each(function(){
			
			c++;
			var id = $(this).attr('idc');
			ids.push(id);

			if(ignoreput === undefined){
				window.studio_blockly.updateFile(id,'sequence',c);
			}

		});

		//window.studio_blockly.updateFile(id,'sequence',c);

		if(igrnoreStateOrder === undefined){

			this.setState({orderFiles:ids});

		}
		
 
	}


	//para selecionar o primeiro arquivo... usado quando deletar algum arquivo ou der "hidden no arquivo que esta ativo"
	selectFirstFile(){

		if(this.state.log)
		console.log('selectFirstFile');

		var first = $('.myTab:visible:first').attr('idc');

		if(this.state.log)
		console.log(first);

		this.preGetFile(first);

 
	}

	//retorna arquivo por nome
	getFileStateName(nameFile){

		var files = this.state.filesList;
		for (var i = 0; i < files.length; i++) {
			//console.log(i);
			//console.log(this.state.filesList[i].name);
			if(nameFile === this.state.filesList[i].name){ return this.state.filesList[i]; }

		}

		return null;

	}


	//para atualizar um arquivo no backend
	async updateFile(id,colum,value){

		id = parseInt(id);

		var dados = {};
		dados[colum] = value;
		
		var a = await api.updateFile(id,dados).then((res) => {

			var files = this.state.filesList;

			//Assim que atualiza.... pego a posição do arquivo dele no array e substituo
			var elementPos = files.map(function(x) {return x.id; }).indexOf(id); 
        	files[elementPos] = res.data;

        	//console.log('bugadao');
        	//console.log(files);

        	this.setState({filesList:files});

        	//console.log('passou1');

        	window.phaser.setState({a:1});
  
        	setTimeout(function(){ this.resizeBlockly(); }.bind(this), 5);


        	if(colum === "namemin"){ return false; }

        	var obj = {};

		    obj.game = window.studio_blockly.state.game.id;
		    obj.file = id;
		    obj.data = dados;
		    obj.user = window.app.state.userId;

		    //para emetir para o resto do pessoal
		    window.emitir('updateScenes',obj);


        	return true;
        
	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  		return false;

	    });

	    return a;
	}

	//para atualizar o asset
	async updateAsset(id,colum,value){

		id = parseInt(id);

		var dados = {};
		dados[colum] = value;

		if(this.state.log)
		console.log(id,colum,value);

		var update = await api.updateAsset(id,dados).then((res) => {

			if(this.state.log)
        	console.log(res);

        	var Assets = this.state.Assets;
        	//Assim que atualiza.... pego a posição do arquivo dele no array e substituo
			var elementPos = Assets.map(function(x) { return x.id; }).indexOf(id); 
        	Assets[elementPos] = res.data;
        	this.setState({Assets:Assets});
        	window.phaser.setState({a:1});
        	window.assets_a.setState({a:1});

        	return res.data;
        
	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  		return false;

	    });

	    return update;

	}
 	

	componentWillMount(){ 

		//antes de montar, mostro a tela de loading
		window.app.loading(true); 
		console.log('acionou aqui 2')

	}

	componentDidMount(){

		var tempo;
		//escondo todo studio.... para garantir que ninguem veja nada
		$("#pageStudio").css('opacity',0);

		if(this.state.log)
		console.log('componentDidMount');

		//se a tela de loading ja estiver la.... do um timeout
		if($('#page-loader').is(':visible')){

			tempo = 0;

		}else{

			tempo = 3000;

		}

		setTimeout(function(){ 

			//busca os dados do game
			this.carregaTudoV1();

		}.bind(this), tempo);

	 	$("#loadingTxt").html('Carregando dados do projeto');
 
	}

	//para carregar os blocos de um arquivo
	loadBlock(id = $("#fileselected").val()){


		window.$(".myTab").css('pointer-events','none');
		window.$(".item_menu_vertical_tabs").css('pointer-events','none');
		  
		setTimeout(() => { 
			
			window.$(".myTab").css('pointer-events','');
			window.$(".item_menu_vertical_tabs").css('pointer-events','');

		 }, 1500);

		 
		if(this.state.log)
		console.log('loadBlocks: '+id);

		$("#fileselected").val(id);


		var fileCache = window.studio_blockly.state.filesList.find(x => x.id === id);
		if(fileCache !== undefined){

			this.restaura_codigo(fileCache.blocklyxml);
			console.log('restaurou bloco do cache');
			return false;

		}
		 

		api.getFile(id).then((res) => {

			//restuaro o codigo 
			if(parseInt(res.data.editor) === 0){ this.restaura_codigo(res.data.blocklyxml); }
			if(parseInt(res.data.editor) === 1){ window.acereditormaster.restauraCodigoAceEditor(res.data); window.studio.showCanvas(); }
    		
    		this.resizeBlockly();

			setInterval(() => { this.setState({loadingFile:false}); },500);
			
        
	    }).catch(error => {
 
			setInterval(() => { this.setState({loadingFile:false}); },500);

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  
	    });

	}

	//carrega todas as categorias de toolbox do sistema
	async carregaListaToolBox(){

		var carr = await api.getListToolBox({}).then((res) => {

			//console.log(res.data);
        	this.setState({toolBoxList:res.data});
        	return true;

	    }).catch(error => {

	    	console.log(error.data);
	    	console.log(error);
	    	console.log(error.response);
	  		return false;

	    });

	    return carr;

	}


	  async getHelperStudio(){

	    await api.getStudioHelper().then((res) => {

	        $("#jsHelper").html(res.data);

	    }).catch(error => {

	          console.log(error.data);
	          console.log(error);
	          console.log(error.response);
	    
	    });

	  }
 

	//pega o xml do toolbox
	async getToolBox(id,toolbox){

	 
		console.log('pegou tool: '+id);


		const exec = ((data,toolbox) => {

			$("#xmlBlocks").html(data);

			//passo por paramentro, pois a primeira chamada ele ja puxa o primeiro xml
			if(toolbox === true){ 

				if(this.state.log)
				console.log('toolbox: true');
				window.demoWorkspace.updateToolbox(document.getElementById('toolbox')); 

				setTimeout(function(){ this.resizeBlockly(); }.bind(this), 10);

			}else{

				this.setState({getXmlBlocks:true});

			}

		});
		
		if(window.cachetool === undefined){ window.cachetool = {}; }
		if(window.cachetool['tool_id_'+id] !== undefined){

			console.log('carregou tool do cache ', toolbox);
			exec(window.cachetool['tool_id_'+id],toolbox);
			return false;

		}

		//para salvar os settings
		await api.getToolBox(id).then((res) => {
 
			exec(res.data,toolbox);
			window.cachetool['tool_id_'+id] = res.data;

	    }).catch(error => {

	    	console.log(error.data);
	    	console.log(error);
	    	console.log(error.response);
	  
	    });

	}


	aplicaEventoSelectToolBox(){

		return false;

		/*$('#selectToolBox').hover(function() {


			$(this).attr('size', $(this).find('option').length+'px');
			console.log($(this).find('option').length);
			var ops = $(this).find('option').length;
			
			var altura = 29;
			var multiplica = altura * ops;
			console.log(multiplica);
			$(this).css('height', multiplica+'px');
			$(this).css('zIndex','9999');
			$(this).css('overflowY','hidden');
			
			$(this).find('option').css('marginLeft','-7px');

		}, function() {

		  $(this).css('height', '29px');
		  $(this).attr('size', 1);

		});

		$('#selectToolBox').click(function(){ 

		  $(this).css('height', '29px');
		  $(this).attr('size', 1);

		});*/


	}


	//pega todos os blocos cadastrados PEGA O GENERATOR E DEFININTION BLOCK
	async getAllJsBlocks(){
		
		if(this.state.log)
		console.log('getAllJsBlocks');
		
		var getAllJsBlocks = await api.getAllJsBlocks({}).then((res) => {

			this.setState({getBlocksJs:true});
			//console.log(res.data);
			$("#jsBlocks").html('<script>'+res.data+'</script>');
			return true;

	    }).catch(error => {

	    	console.log(error.data);
	    	console.log(error);
	    	console.log(error.response);
	  		return false;

	    });

	    return getAllJsBlocks;

	}

	//para dar o render padrão de "options..."
	renderOption(dados){

		return ( <option key={dados.id} value={dados.id}>{dados.name}</option> )

	}





	async carregaTudoV1(){

			console.log('carregador acionado');

			await this.getHelperStudio();

			var game = await this.carregaDados();
			if(game !== true){ return false; }

			if(this.state.log)
			console.log('up1');

			this.setState({loadgetBlocksJs:false});
			await this.getAllJsBlocks();
			$("#loadingTxt").html('Carregando blocos');
	
 
			if(this.state.log)
			console.log('up2');

			this.setState({loadgetAllFiles:false});
			await this.getAllFiles();

			$("#loadingTxt").html('Carregando files');

			console.log('fim up2');

 

			if(this.state.log)		
 			console.log('up3');

 			this.setState({loadgetToolBox:false});
			await this.getToolBox($("#selectToolBox").val());



			$("#loadingTxt").html('Carregando ToolBox');

			console.log('fim up3');

			setTimeout(function(){ this.carregaTudoV2(); }.bind(this), 500);


	}


	async carregaTudoV2(){


			if(this.state.log)
			console.log('up4');	

			var ops = {media: '/media/',toolbox: document.getElementById('toolbox'),zoom:{controls: true, wheel: true, startScale: 1.0, maxScale: 3, minScale: 0.3, scaleSpeed: 1.2}, trashcan: true};

			//controls: true, wheel: true, startScale: 1.0, maxScale: 3, minScale: 0.3, scaleSpeed: 1.2
			window.demoWorkspace = window.Blockly.inject('blocklyDiv',ops);

			setTimeout(function(){

				if(this.state.log)
				console.log('add event');

				window.demoWorkspace.addChangeListener(window.studio_blockly.salvaXML); 






			}.bind(this), 500);


			this.setState({blocklyReady:true});

			$("#loadingTxt").html('Iniciando blockly');


			$("#loadingTxt").html('Carregando file no editor');

			if(this.state.log)
			console.log('up5');

			this.setState({blocklyReady2:true});
			$("#pageStudio").css('opacity',1);
			

			//this.resizeBlockly();

			window.studio.setState({a:'1'});

			$(window).resize(function(){ this.resizeBlockly(); }.bind(this));
 
 

			//para marcar o checkbox
			if(this.state.game.preservelog === true){

				$("#inputPreservarLog").prop('checked', true);

			}

			//para ajustar a tela
			window.phaser.calculaAltura();

			//para setar a fonte do ace editor
			window.acereditor.fontsize = this.state.game.fontSize;
			$("#tamanhoFontAcer").html(window.acereditor.fontsize+'px');
			
			//verifica o tamanho da workspace... se tiver zerado ele reseta
			if((this.state.game.widthcanvas === "" || this.state.game.widthcanvas === 0) || this.state.game.widthcanvas >= $(window).width()){

				setTimeout(function(){ window.studio.resetWorksPace(); }, 200);

			}

			//para verificar se tem defaultcanvas size
			this.defaultCanvasSize();
  
			window.assets_a.setState({assetAbaDefault:this.state.game.assetAbaDefault});
 

//			console.log('Buscando todos os assets');
			$("#loadingTxt").html('Buscando todos os assets');

			await this.getAllAssets();
			this.setState({getAllAssets:true});




			$("#loadingTxt").html('Buscando códigos padrões');

			await this.getDefaultstartcode();
			this.setState({getDefaultCodes:true});
  
			setTimeout(function(){ 

					//caso não tenha nenhum arquivo, ele cria o main
				if($(".myTab").length === 0){ 

					this.createFile('Main','scene');

				}else{ 

					this.loadBlock(); 

				}


				//seleciona o primeiro arquivo caso o arquivo que esteja no "fileSelected... seja inexistente"
				if($('.rdTab[idc='+this.state.game.fileSelected+']').length === 0){

					console.log('selecionou o primeiro');
					this.selectFirstFile();

				}

				window.app.loading(false); 

				$(".optionHeaderStudio2:visible:first").click();
				
			}.bind(this), 300);


//			console.log('acionou aqui 1');

			this.resizeBlockly();

			this.aplicaEventoSelectToolBox();


			//caso so tenha um arquivo, e o JS dele esteja em branco... eu gero tudo....
			if(window.studio_blockly.state.filesList.length === 1){

				if(window.studio_blockly.state.filesList[0].javascript === ""){

					await this.regenerateAllCodes();
					//window.phaser.startGame();

				}

			}


	}





	//sempre que der update no state
	componentDidUpdate(){
 

	}

	async defaultCanvasSize(){


//		console.log('verificou defaultCanvasSize');
		var width = window.studio.state.defaultCanvasWidth;
		var height = window.studio.state.defaultCanvasHeigth;

		if(width !== null && width !== 0 && width !== "" && width !== undefined){

			//window.phaser.alteraLargura(width);
			$("#divIframePhaser").css('width',width+'px');
			console.log('dimensionou dd');
			$("#exampleInputEmail3").val(width);
		}

		if(height !== null && height !== 0 && height !== "" && height !== undefined){

			//window.phaser.alteraAltura(height);
			$("#divIframePhaser").css('height',height+'px');
			$("#exampleInputEmail4").val(height);
			console.log('dimensionou dd'); 

			await this.saveSettings();
			window.phaser.startGame();
		
		}



	}

	componentWillUnmount(){

		console.log('desmontou');

		delete window.demoWorkspace;
		$(window).unbind();


		//delete window.studio_blockly;
		//delete window.acereditor;
		//delete window.phaser;
		//delete window.assets_a;
		//delete window.files_a;
  
	}

	//pega todos os arquivos do game
	async getAllFiles(){

		if(this.state.log)
		console.log('getAllFiles');

		var all = await api.getFiles(window.studio.props.match.params.game).then((res) => {

			if(this.state.log)
        	console.log(res.data);

        	var order = [];
        	res.data.forEach(function(item,index,array){ order.push(item.id.toString()); });

        	if(this.state.log)
        	console.log({getFiles:true,filesList:res.data});

        	this.setState({getFiles:true,filesList:res.data});
        	//window.files_a.setState({order:order});
  			return res.data

	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  		return false;

	    });

	    return all;

	}
  	
	//para salvar o toolbox e o file
	async saveSettings(ignore_realtime){
 
		if(this.state.log)
		console.log('saveSettings');

		var ck = false; if($("#inputPreservarLog").is(':checked')){ ck = true;}
		var advanced = 0; if($("#selectAdvancedMode").val() === "1"){ advanced = 1;}
		
		await api.saveSettings(window.studio.props.match.params.game,{debug:this.state.game.debug,advanced:advanced,assetAbaDefault:$(".optionHeaderAsset.ativo").attr('idc'),aceEditorFontSize:$("#aceEditorFontSize").html(),aceEditorTheme:$("#selectThemeAce").val(),preservelog:ck,toolBoxSelected:$("#selectToolBox").val(),fileSelected:$("#fileselected").val(),widthcanvas:$("#blocklyDiv").width(),

			phaserwidth:$("#divIframePhaser").width(),
			phaserheigth:$("#divIframePhaser").height()

		}).then((res) => {

			if(this.state.log)
        	console.log(res.data);
        	this.setState({game:res.data});

	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  
	    });

	    window.phaser.calculaAltura();

	    if(ignore_realtime !== undefined){ return false; }

	    var obj = {};

	    obj.game = window.studio_blockly.state.game.id;
	    obj.user = window.app.state.userId;

	    //para emetir para o resto do pessoal
	    window.emitir('syncProjeto',obj);
	    
	}

	//para pegar o toolbox
	async preGetToolBox(id){

		if(this.state.log)
		console.log('preGetToolBox '+id);

		var game = this.state.game;
		game.toolBoxSelected = id;

		this.setState({game:game});

		await this.saveSettings();
		await this.getToolBox(id,true);

	}


	//para pegar o arquivo
	async preGetFile(id){

		if(this.state.log)
		console.log('preGetFile '+id);

		var game = this.state.game;
		game.fileSelected = id;

		this.desocultarFile(id);

		//se o id do arquivo que estou olhando
		await this.setState({game:game,loadingFile:true});

		//busco o arquivo la no servidor
		await this.loadBlock(id);
		await this.saveSettings(1);

	}


	//para gera o json dos assets
	generateAssets(tipo){

		

		var assets = [[{"src":'/media/invisible.png',"width":0,"height":0,"alt":"*"},'/media/invisible.png']];

		if(tipo === "image"){

			$(".imageAsset").each(function(){

				var dados = [{"src":$(this).find('img').attr('src'),"width":35,"height":35,"alt":"*"},$(this).find('img').attr('src')];
				assets.push(dados);
				 
			});

		}

		if(tipo === "audio"){

			$(".elemento").each(function(){


			});

		}
 
		return assets;

	}
  	
  	//para verificar o status do checkbox do file
	checkBoxStatus(event){

		if($(event.target).is(":checked")){ 

			this.updateFile(event.target.value,'status',true);

		}else{ 

			this.updateFile(event.target.value,'status',false);

		}

	}

	//para altera o nome do file
	async updateNameFile(id,value){

		console.log(id);
		console.log(value);

		var txt = value;

		await this.updateFile(id,'name',value);

		txt = txt.replace(/ /g,'');
		txt = txt.toLowerCase();

		await this.updateFile(id,'namemin',txt+'.js');

 

	}

	//para altera o nome do asset
	updateNameAsset(id,value){

	 	var namemin = value.replace(/ /g,'');
		namemin = namemin.toLowerCase()+'.js';

		this.updateAsset(id,'name',value); 
		this.updateAsset(id,'namemin',namemin);

		//verifica se o asset tem arquivo associado, se tiver... trocar o nome do arquivo também

		if(this.state.game.advanced === 0){

			this.state.Assets.forEach(function(item,index,array){

				if(parseInt(item.id) === parseInt(id)){ 

					this.updateNameFile(item.file.id,value);

				}

			}.bind(this));

		}else{ console.log('verificou file aqui'); }
			

	}

	 

	//para ocultar um arquivo
	ocultarFile(id){
 		
		if($('.rdTabWrapper').find('li:visible').length === 1){ 

			toast.error('Você deve ter no mínimo 1 arquivo na tela.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true});
			return false;
		
		}

		//toast.info('O arquivo foi ocultado, mas continua ativo.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true});
		this.updateFile(id,'hidden',true);

		if(parseInt(id) === parseInt(this.state.game.fileSelected)){

			if(this.state.log)
	  		console.log('selectionou o primeiro');

	  		this.selectFirstFile();

	  	}

	}

	desocultarFile(id){

		//toast.info('O arquivo voltou para o workspace.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true});
		this.updateFile(id,'hidden',0);


	}

	renderTableAssets(dados){

		return(

			<tr key={dados.id} idc={dados.id}>
		      <th scope="row"><i style={{cursor:'n-resize'}} className="fas fa-arrows-alt-v"></i></th>
		      <td><input onKeyUp={ (event) => window.studio_blockly.updateNameAsset(dados.id,event.target.value) } className="form-control" defaultValue={dados.name} /></td>
		      <td></td>
		      <td>
		      	<center>
		      		
	      		<Link to={process.env.REACT_APP_SERVER_URL+':'+process.env.REACT_APP_PORT+dados.file.url} target="_blank" ><button type="button" className="btn btn-primary btn-sm"><i className="far fa-eye"></i></button></Link>
		      	 
		      	 <button style={{marginLeft:'5px'}} onClick={ () => window.studio_blockly.removeAsset(dados.id) } type="button" className="btn btn-danger btn-sm"><i className="far fa-trash-alt"></i></button>

		      	</center>
		      	</td>
		    </tr>

		)

	}


	//para enviar o arquivo para o servidor
	sendAsset(){

		let formData = new FormData();

		var file = $("#inputAsset")[0].files[0];
		formData.append('files', file); 
		formData.append('name', file.name);
 
		api.upload(formData).then((res) => {

			if(this.state.log)
        	console.log(res.data[0].id);

        	this.newAsset(res.data[0]);

	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  
	    });

	}

	//reseta a workspace atual
	updateWorkspace(){
 
		this.preGetFile(this.state.game.fileSelected);

	}

	//para cadastrar um novo asset no game e O ID do paramentro e o id do arquivo enviado
	async newAsset(file){

		console.log(file);

		//para pegar o tipo de arquivo que esta sendo enviado.
		var type = $("#tipoAssetEnviado").val();

		//procuro o codigo xml padrão caso
		var codeAcer = undefined;
		var xmlBlcokly = undefined;

		this.state.defaultstartcode.forEach(function(item,index,array){

			//console.log(item.type+' === '+parseInt(type)+' && '+parseInt(item.editor)+' === '+'1');

			if(codeAcer === undefined && item.type === parseInt(type) && parseInt(item.editor) === 1){

				codeAcer = item.code;

			}

			if(xmlBlcokly === undefined && parseInt(item.type) === parseInt(type) && parseInt(item.editor) === 0){

				xmlBlcokly = item.code;

			}

		});

		 

		console.log(file.name,'asset',codeAcer,xmlBlcokly);

		//para criar um arquivo
		var dataFile = await this.createFile(file.name,'asset',codeAcer,xmlBlcokly);

		var asset = await api.postAsset({file:dataFile.id,"game":window.studio.props.match.params.game,upload_id:file.id,name:$("#keyNovoAsset").val(),type:type}).then((res) => {

			if(this.state.log)
    		console.log(res.data); 
    		this.getAllAssets();

    		return res.data;

		}).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  		return false;
	    });

		
	    return asset;

	}

	//para remover o asset do game
	removeAsset(id){

		Swal.fire({
		  title: 'Você tem certeza?',
		  text: 'Depois de excluir o arquivo, é impossível recuperar.',
		  type: 'warning',
		  showCancelButton: true,
		  confirmButtonText: 'Sim',
		  cancelButtonText: 'Não cancelar'
		}).then((result) => {

		  if(result.value){

 			this.removeAssetApi(id);

		  } else if (result.dismiss === Swal.DismissReason.cancel) {
		   

		  }

		});


	}

	async removeAssetApi(id){
 
		//remove o arquivo na api
  	 	await api.removeAsset(id).then((res) => {

  	 		if(this.state.log)
        	console.log(res);
 
        
	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  
	    });

	    console.log('removou na api');

	    await this.getAllAssets(1);
	     await this.getAllFiles();

	    //se o arquivo selecionado não existir
	    if($('.myTab.ativo[idc='+this.state.game.fileSelected+']').length === 0 ){ this.selectFirstFile(); }
	     

    	if(!this.state.game.advanced){

        	var asset = this.getAssetState(id);
			//se ele estiver associado a algum arquivo ele também remove o arquivo
			if(asset){

				if(asset.gamefiles !== null){

					await this.removeFileApi(asset.gamefiles.id);
					//console.log('removeu na api '+asset.gamefiles.id);

				}

			}

		}

		var obj = {};

		obj.game = window.studio_blockly.state.game.id;
	    obj.user = window.app.state.userId;
	    obj.reset = 1;

	    //para emetir para o resto do pessoal
	    window.emitir('syncScenes',obj);


	}

	//pega todos os assets do game
	async getAllAssets(resetAssetsPane){

	 
	//	console.log('getAllAssets');

		await api.getAllAssets(window.studio.props.match.params.game).then((res) => {

    		this.setState({Assets:res.data});

    		var order = [];
    		res.data.forEach(function(item,index,array){ order.push(item.id.toString()); });
			
//			console.log(order);

			if(resetAssetsPane === 1){ window.assets_a.setState({SortablePane:false}); }

    		window.assets_a.setState({order:order});

    		if(resetAssetsPane === 1){ setTimeout(function(){ window.assets_a.setState({SortablePane:true}); }, 200); }

		}).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  
	    });

	    return true;

	}

		//redimenciona todo o studio
	resizeBlockly(){


		//console.log('deu resize');
		//pego altura da tela
		var altura = $(window).height();

		//nova altura

		var diminuidor = 0;

		diminuidor = diminuidor + parseInt($(".studio_2").css('margin-top'));
		diminuidor = diminuidor + parseInt($(".rdTabWrapper").height());
 

		var altura2 = altura - diminuidor;

		//pego a lagura da div resizable
		var largura = $("#resizeBlockly").width();

		//div do blockly injetado
		var blocklyDiv = document.getElementById('blocklyDiv');
		blocklyDiv.style.height = altura2+'px';
		blocklyDiv.style.width = largura+'px';

		//div do ace editor
		var aceEditorDiv = document.getElementById('aceEditorDiv');
		if(aceEditorDiv !== null){

			aceEditorDiv.style.height = altura2+'px';
			aceEditorDiv.style.width = largura+'px';

		}

		//Caso o jsonEditor esteja visivel na tela
		if(window.jsonEditor !== undefined){

			window.jsonEditor.setState({height:altura2,width:largura});

		}
		


	    var laguraPhaser = $(window).width() - largura;
	    $("#divPhaser").css('width',laguraPhaser+'px');

	   	//console.log('Largura phaser: '+laguraPhaser);


	    //caso o blockly não exista.... nem faz nada pra evitar erro
	    if(window.Blockly !== undefined){ 

	    	window.Blockly.svgResize(window.demoWorkspace); 

	    }

	    //para atualizar o ace editor
	    if(window.acereditor){

	    	var altura3 = altura - 32;
	    	window.acereditor.setState({largura:altura3+'px'});
	    	this.geraJS();

	    }


	   	var laguraToolBox = $('.blocklyToolboxDiv').width();
	   	$(".rdTabWrapper").find('ul').css('padding-left',laguraToolBox+'px');


	   	window.phaser.calculaAltura();

	   	
		//parar dimencionar o select do toolbox
	   	$('#selectToolBox').css('width',$('.blocklyToolboxDiv').width()+'px');

	   	
	   	 



	}

	
	render() {

		var styleRes = {top: {display:'none'},topLeft: {display:'none'},left: {display:'none'},bottomLeft: {display:'none'},bottom: {display:'none'},bottomRight: {display:'none'},right: {marginTop:'36px',flex: '0 0 auto',width: '18px', background: 'url(https://raw.githubusercontent.com/RickStrahl/jquery-resizable/master/assets/vsizegrip.png) center center no-repeat #535353',minHeight: '200px',cursor: 'col-resize',zIndex: '1',right: '-18px'},topRight: {display:'none'}};
 		 

		return(<>
 
				<select hidden id="fileselected" value={this.state.game.fileSelected} className="form-control" onChange={ (event) => this.preGetFile(event.target.value) } >{ this.state.filesList.map( this.renderOption ) } </select> 
				<button hidden type="button" className="btn btn-primary btn-sm" data-toggle="modal" data-target="#filesModal"><i className="far fa-folder-open"></i></button>
				<button hidden type="button" className="btn btn-primary btn-sm" data-toggle="modal" data-target="#assetsModal"><i className="fas fa-band-aid"></i></button>
  
				<div style={{display:'flex'}}>
  
					{
						( this.state.game.widthcanvas !== undefined) &&
						<Resizable id="resizeBlockly" defaultSize={{ width:this.state.game.widthcanvas+'px' }} handleStyles={ styleRes } handleWrapperClass="splitter" handleClasses="splitter" onResize={(e, direction, ref, d) => { this.resizeBlockly(); }} onResizeStop={() => { this.saveSettings(); }}  >

							<TableFiles/>

							<div style={{position:'relative'}}>
								{

									(this.state.loadingFile === true) &&
									<div style={{display:'flex',justifyContent:'center',alignItems:'center',position:'absolute',width:'100%',height:'100%',zIndex:'9999'}}>
										<div style={{marginLeft:window.$('.blocklyToolboxDiv').width()+'px'}}><ClipLoader color="#fff" size="60" /></div>
									</div>

								} 

								<div id="blocklyDiv"></div>
  								<AceEditorMaster/>

							</div>
							 
							

  							{
  								//mostra o editor de json
  								(this.getFileState(this.state.game.fileSelected).type === "json") &&
  								<JsonEditor state={this.state} />

  							}
  							

  							 

						</Resizable>
					}
			 		

				</div>
 				
				<div id="xmlBlocks" hidden></div>
				<div id="jsBlocks" hidden></div>

				<div id="jsHelper" hidden></div>

 

		</>);

  	}


}

 


export default Blockly;