import React from 'react';
import{ Link } from 'react-router-dom';
//import $ from 'jquery';
 
import ListaProjetos from './modal/ListaProjetos';

//mport api from './Api'; 

class Header extends React.Component {
  
  constructor(props){

    super(props); 

    window.Headedd = this;
    
  }

  componentDidMount(){ window.aaaee = this; }

  render() {
     
    return (<>

      <header id="header" className="" style={{display:'nsone'}}>        
        <div className="header-inner wrapper clearfix">
                                
          <div id="logo" className="left-float">

            <Link to="/" id="defaut-logo" className="logotype" >
              <img src="/media/csplus-logo.svg" alt="Logo" style={{height:'85px',marginTop:'-7px'}} />
            </Link>

            <Link to="/" id="defaut-logo" className="logotype" >
              <h3 className="subtitle" style={{display: 'flex',marginLeft:'110px'}}>
                <font style={{position:'absolute',top:'8px',marginLeft:'-35px',fontSize:"20px",fontWeight:'300'}}>Game Engine</font>
              </h3>
            </Link>

          </div>    
                
          <div className="menu right-float clearfix">
          <nav id="main-nav">
              <ul>
                  <li className="current-menu-item"><button href="#portfolio" className="scroll-to"><Link to="/" style={{textDecoration:'none',color:'#fff'}}>Meus projetos <i className="fas fa-gamepad"></i></Link></button></li>
                  <li><button onClick={ () => window.$("#novoProjetoModal").modal() } className="scroll-to">Novo projeto <i className="fas fa-plus"></i></button></li>
                  
                  { (window.app.state.dataUser !== undefined) && 
                    <>
                      {
                        (parseInt(window.app.state.dataUser.admin) === 1) && 
                        <>
                        <li><button className="scroll-to"> <Link to="/configuracao" style={{textDecoration:'none',color:'#fff'}}>Administração <i className="fas fa-user-cog"></i></Link> </button></li>
                        </>
                      }
                    </>
                  }

                 <li><button onClick={ () => window.app.removeSession() } type="button" className="scroll-to">Sair <i className="fas fa-sign-out-alt"></i></button> </li>
              </ul>
          </nav>

          </div>
                        
        </div>
      </header>


      <ListaProjetos/>

    </>);


  }


}

export default Header;
