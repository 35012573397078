import React, { Component } from 'react';
import $ from 'jquery';

import AceEditor from 'react-ace';
import api from './../Api';

import 'brace/mode/javascript';
import 'brace/theme/monokai';
import 'brace/theme/github';
import 'brace/theme/solarized_dark';
import 'brace/theme/terminal';

import 'brace/snippets/html';
import 'brace/ext/language_tools';


class AceEditorMaster extends Component {
  
	constructor(props){

		super(props);
		this.state = {defaultAceEditorValue:"",aceEditorDivEventClick:false,fontSize:window.studio_blockly.state.game.aceEditorFontSize};
		this.aceEditorMasterChange = this.aceEditorMasterChange.bind(this);
		this.restauraCodigoAceEditor = this.restauraCodigoAceEditor.bind(this);
		this.changeTheme = this.changeTheme.bind(this);

		window.acereditormaster = this;

	}


  	aceEditorMasterChange(scrool){
 	
		var posicao = this.refs.aceEditorMaster.editor.getCursorPosition();
		var valor = this.refs.aceEditorMaster.editor.getValue();

		var dados = {javascriptAceEditor:valor,positionAceEditor:JSON.stringify(posicao)};

		if(scrool !== undefined){ 

			dados.scrollTop = window.$("#aceEditorDiv").scrollTop();

		}
			
		console.log(dados);
		
		this.setState({defaultAceEditorValue:valor});
		api.updateFile($("#fileselected").val(),dados).then((res) => {

			if(this.state.log)
        	console.log(res);
        
	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  
	    });

	    console.log(dados);
		return false;

	}
  
	restauraCodigoAceEditor(dados){

		setTimeout(function(){ window.$("#aceEditorDiv").scrollTop(dados.scrollTop); }, 2);

		//console.log(dados.javascriptAceEditor);
		this.setState({defaultAceEditorValue:dados.javascriptAceEditor});
		this.refs.aceEditorMaster.editor.moveCursorToPosition(dados.positionAceEditor);
		this.refs.aceEditorMaster.editor.focus();
  
	}

	componentDidUpdate(){

		if(this.state.aceEditorDivEventClick === false){
			
			this.setState({aceEditorDivEventClick:true});
			
			$("#aceEditorDiv").click(function(){ this.refs.aceEditorMaster.editor.focus() }.bind(this));

			$("#aceEditorDiv").scroll(function(){   clearTimeout($.data(this, 'scrollTimer'));
		    $.data(this,'scrollTimer', setTimeout(function(){ window.acereditormaster.aceEditorMasterChange(1); }, 250)); });
 
			var timeOut = 0, t;

			$('.buttonChangeFontSizeAce').on('mousedown touchstart', function(e) {
				
				if($(this).attr('tp') === "+"){ t = 1}
				if($(this).attr('tp') === "-"){ t = -1}

				window.acereditormaster.changeFontSize(t);
				window.studio_blockly.saveSettings();

				timeOut = setInterval(function(){

				  console.log('a');
				  window.acereditormaster.changeFontSize(t);
				  window.studio_blockly.saveSettings();

				}, 100);

			}).bind('mouseup mouseleave touchend', function() {
				//$(this).removeClass('active');
				clearInterval(timeOut);
			});

		}

	}

	changeFontSize(i){

		var fontSize = this.state.fontSize;
		fontSize = fontSize + i;

		if(fontSize === 15 || fontSize === 41){ return false; }
		this.setState({fontSize:fontSize});

	}

	changeTheme(name){

		window.studio_blockly.saveSettings();
		var game = window.studio_blockly.state.game;
		game.aceEditorTheme = name;
		window.studio_blockly.setState({game:game});

	}

  	render(){

		var showAcerEditor = false;
		//var showAcerJsonEditor = false;

		//apra saber se deve exibir o ace editor ou o blockly
		window.studio_blockly.state.filesList.map((item, i) => { 
			
			if(parseInt(item.id) === parseInt(window.studio_blockly.state.game.fileSelected)){

				if(parseInt(item.editor) === 1){ showAcerEditor = true; } 

			} 

			return ""; 

		})

		var theme = "default";

		if(window.studio_blockly.state.game.aceEditorTheme === "default"){ theme = "monokai"; }
		if(window.studio_blockly.state.game.aceEditorTheme === "monokai"){ theme = "monokai"; }
		if(window.studio_blockly.state.game.aceEditorTheme === "github"){ theme = "github"; }
		if(window.studio_blockly.state.game.aceEditorTheme === "solarized_dark"){ theme = "solarized_dark"; }
		if(window.studio_blockly.state.game.aceEditorTheme === "terminal"){ theme = "terminal"; }

	    return (<> 
	 
	    	{ 

				(showAcerEditor === true) &&
				<link rel='stylesheet' id='mqueries-style-css' href='/css/customAceEditor.css' type='text/css' media='all' />

			}

			{ 

				(window.studio_blockly.state.game.aceEditorTheme === "default" && showAcerEditor) &&
				<link rel='stylesheet' id='mqueries-style-css' href='/css/defaultAceEditor.css' type='text/css' media='all' />

			}

			{ 

				(window.studio_blockly.state.game.aceEditorTheme === "monokai" && showAcerEditor) &&
				<link rel='stylesheet' id='mqueries-style-css' href='/css/defaultMonokaiAceEditor.css' type='text/css' media='all' />

			}

			{ 

				(window.studio_blockly.state.game.aceEditorTheme === "github" && showAcerEditor) &&
				<link rel='stylesheet' id='mqueries-style-css' href='/css/defaultGithubAceEditor.css' type='text/css' media='all' />

			}

			{ 

				(window.studio_blockly.state.game.aceEditorTheme === "solarized_dark" && showAcerEditor) &&
				<link rel='stylesheet' id='mqueries-style-css' href='/css/defaultSolarizedAceEditor.css' type='text/css' media='all' />

			}

			{ 

				(window.studio_blockly.state.game.aceEditorTheme === "terminal" && showAcerEditor) &&
				<link rel='stylesheet' id='mqueries-style-css' href='/css/defaultTerminalAceEditor.css' type='text/css' media='all' />

			}

		 
			 

	    	<div id="aceEditorDiv" style={{overflowY:'auto',display:'none'}}>
	 
				<AceEditor

				    mode="javascript"
				    onChange={() => this.aceEditorMasterChange()}
				    ref="aceEditorMaster"
				    theme={theme} 
				    name="aceEditorMaster"
				    editorProps={{$blockScrolling: true}}
				    width="auto"
				    fontSize={this.state.fontSize}
				    height={window.studio_blockly.state.largura}
				    showPrintMargin={false}
				    maxLines={Infinity}
				    value={this.state.defaultAceEditorValue}
				    onCursorChange={() => this.aceEditorMasterChange()}
			      	setOptions={{enableBasicAutocompletion: true,enableLiveAutocompletion: true,}}
			   
			  	/>

			</div>


			<div className="customConfigAcer" style={{display:'none'}}> 

				  	<button className="alterCanvasSize buttonChangeFontSizeAce" tp="-"><i className="fas fa-minus iconSizeFontAce"></i></button> <button tp="+" className="alterCanvasSize buttonChangeFontSizeAce"><i className="fas fa-plus iconSizeFontAce"></i></button>  <font className="sizeFontAce"> <font id="aceEditorFontSize">{ this.state.fontSize }</font>px</font> 

				  	<select defaultValue={ window.studio_blockly.state.game.aceEditorTheme } onChange={ (event) => this.changeTheme(event.target.value) } className="selectThemeAce" id="selectThemeAce">
				  		
				  		<option value="default" style={{color:'#000'}}>Default</option>
				  		<option value="monokai" style={{color:'#000'}}>Monokai</option>
				  		<option value="github" style={{color:'#000'}}>Github</option>
				  		<option value="solarized_dark" style={{color:'#000'}}>Solarized</option>
				  		<option value="terminal" style={{color:'#000'}}>Terminal</option>

				  	</select>

		  	</div>



	    	</>)


	}

}

export default AceEditorMaster;