import React, { Component } from 'react';

//import bg from './assets/images/bg-3.jpg';
//import logo from './assets/images/logo.png';
import api from './Api';
import $ from 'jquery';

//console.log('chamou api');
class Login extends Component {

  constructor(props){

    super(props); 
 

    this.fazLogin = this.fazLogin.bind(this);
    this.state = {logando:false,tipo_formulario:'login',erro_login:'',mostra_logo:0,login:'',loginErro:''};

  }

  componentDidMount(){
       
      setTimeout(function(){ 
         
        $(".row_login").fadeIn(1000);

      }, 400);

      setTimeout(function(){ 

        $("#backlogin").fadeIn(1600);
    
      }, 1300);

      setTimeout(function(){ 
 
        $("#backlogin").fadeOut(0);

      }, 450);

  }

   

  fazLogin(event){
  
  	  event.preventDefault();

	    var dados = {user: $('#login').val(),password: $('#senha').val()};
      this.setState({logando:true});
 
      api.login(dados).then((res) => {

		    console.log(res.data);
  	    console.log(res.data.session);
        
        window.app.loading(true);

        setTimeout(function(){

          sessionStorage.setItem('session', res.data.session);
          sessionStorage.setItem('userId', res.data.user.id);

          window.cookies.set("session", res.data.session,{path:"/"});
          window.cookies.set("userId", res.data.user.id,{path:"/"});

          this.props.setSession(res.data);
          this.setState({logando:false});

        }.bind(this), 1200);
    	  
 
          
      }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);

          if(error.response.status === 400){

          	this.setState({loginErro:error.response.data[0].msg});

          }

          this.setState({logando:false});

      });

 

  }

  render() { 


    return (<>

    <link rel='stylesheet' id='mqueries-style-css'  href="/css/login.css" />

    <div id="bd1">
    </div>
     

    <div className="row_login" style={{display:'none'}}>
    <div className="row">
      <div className="col-12 col-sm-6 col-md-6 col-xs-6 col-lg-3 mx-auto logindiv">
        <form className="form-signin" onSubmit={ (event) => this.fazLogin(event) }>

          <center>

          <img src="/media/csplus-logo.svg" alt="" style={{height:'120px',marginBottom:'-5px'}}/>
          <br/>
          <font style={{ top:'23px',whiteSpace:'nowrap',fontSize:'21px',color:"#fff"}}>Game Engine</font>
          <br/>
          <div style={{marginTop:"5px"}} className="divIniciarsessao">Iniciar sessão</div>

          </center>
          <div style={{display:'flex',paddingLeft:'20px'}}>
            
            <div>

              <h3 className="subtitle" hidden style={{display: 'flex'}}>
                
                <font style={{position:'absolute',top:'55px',fontSize:'20px',eft:'215px'}}>Login</font>

              </h3>

            </div>

          </div>
 
              
 

                    { (this.state.loginErro !== "") &&
                      <div className="alert alert-danger animated fadeIn" role="alert">{this.state.loginErro}</div>
                    }

                    <div  >

                      <div>
                      <label className="labelLogin" style={{marginBottom:'0px',color:'white',textTransform:'uppercase',fontSize:'12px'}}>Usuário</label>
                      <input type="text" id="login" className="form-control inputlogin" placeholder="" name="usuario" required autoFocus />
                      </div>

                      <div style={{marginTop:'10px'}}>
                      <label className="labelLogin" style={{marginBottom:'0px',color:'white',textTransform:'uppercase',fontSize:'12px'}}>Senha</label>
                      <input type="password" id="senha" className="form-control inputlogin" placeholder="" name="password" required />
                      </div>

                      { (this.state.logando === false) && 
                        <center> <br/> <button type="submit" className="btn btn-outline-light">Acessar <i className="fas fa-sign-in-alt"></i></button> </center> 
                      }
                      
                    </div>
 
             

            <br/>

            <center> 

              { (this.state.logando === true) &&
                <center> <img class="animated fadeIn" src="https://samherbert.net/svg-loaders/svg-loaders/puff.svg" alt=""/> </center>
              }
              
            </center>

          <br/>
        </form>
      </div>
    </div>
    </div>
    </>);
  }


}

export default Login;