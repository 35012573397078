import React, { Component } from 'react';
//import{ Link } from 'react-router-dom';

import api from './Api';
import $ from 'jquery';
import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';

//import moment from 'moment';

 
jQueryBridget('masonry',Masonry,$);

class ListaGames extends Component {

  constructor(props){

    super(props); 
    this.state = {games:[],formRender:false}; 
   // this.cadastraGame = this.cadastraGame.bind(this);
    //this.acessarGame = this.acessarGame.bind(this);
    this.renderGame = this.renderGame.bind(this);

    window.ListaGames = this; 

  }
 

  componentDidMount(){
 
    api.games({}).then((res) => {

        this.setState({games:res.data});
        this.initMarsory();
        
    }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);
  
    });


  }

   acessarGame(id){
 
    window.app.loading(true);
    setTimeout(function(){ this.props.history.push('/studio/'+id); }.bind(this), 500);  

  }


  initMarsory(){
 
    $('.gameselect').fadeIn();  
 
    setTimeout(function(){ 
 
       
      window.app.loading(false);

    }, 400);   

  }

 


 
  renderGame(dados){

    //var link = '/studio/'+dados.id;

    return(

      <div  className="gameselect" style={{padding:'0px',display:'none'}} key={dados.id}>
        
        <div className="row">
          <div className="col-md-2"> <img  alt="..." style={{maxHeight:'85px',cursor:'pointer'}} onClick={ () => this.acessarGame(dados.id) } src="/media/thumb-g.jpg" /> </div>
          
          <div className="col-md-7">
            <div className="projectNamePrin2">Nome do projeto:</div>
            <div className="projectNamePrin">{dados.name}</div>
          </div>

          <div className="col-md-3">

            <center>
              <button onClick={ () => this.acessarGame(dados.id) } type="button" className="buttonEnterGame top-right-button btn btn-primary btn-lg"><span><i className="far fa-folder-open"></i> Abrir projeto</span></button>
            </center>

          </div>
        </div>
        

      </div>

    )

  }

  render() {
    

    return (<React.Fragment>  
 

      <div className="container" style={{marginTop:'40px'}}>
  
      <div class="section-title" style={{marginBottom:'15px'}}>
        <h2 style={{color:'rgb(255, 255, 255)',fontSize:"30px",marginBottom:'-10px'}}>Meus projetos</h2>
        <div class="seperator size-small"><span></span></div>
      </div>

      { 
        (this.state.games.length !== 0) &&
        this.state.games.map(this.renderGame)
      }

      </div>

      {

        (this.state.games.length === 0) &&
        <center style={{marginTop:'112px'}} ><font className="nenhumProjeto">Nenhum projeto foi adicionado.</font></center>

      }
      
    </React.Fragment>);


  }


}

export default ListaGames;