import React, { Component } from 'react';
import Tooltip  from 'rc-tooltip';
import { SortablePane, Pane } from 'react-sortable-pane';

import api from './../Api';

export const paneStyle = {height:'20px'};
export const textStyle = {fontSize: '32px',fontWeight: 'bold',color: '#aaa'};

class FileEdit extends Component {

    constructor(props){ 

      super(props);
      this.state = {SortablePane:true,order:[]};
      window.files_a = this;

    }  

    async updateOrder(order){
 

      //this.state.order.forEach(function (item, index, array){

        //window.studio_blockly.state.filesList.forEach(function (item2,index2,array2){

          //if(parseInt(item2.id) === parseInt(item)){ files.push(item2); }

        //});

      //});

      //window.studio_blockly.setState({filesList:files});
      //window.studio_blockly.updateSequence(undefined,1);



      order.forEach(function(item,index,array){


        api.changeOrderFileList(item,index).then((res) => {

          //if(this.state.log)
            console.log(res.data);
          

        }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);
          return false;

        });


      });

       


       




    }
         
    render() {


      var panes = [];

      window.studio_blockly.state.filesList.map((value, i) => {
 
        if(window.studio_blockly.state.game.advanced){ panes.push(value); }
        if(!window.studio_blockly.state.game.advanced){ if(value.type === "scene"){ panes.push(value); } }

        return "";
        
      });

      var cp1,cp2;

      if(window.studio_blockly.state.game.advanced === 1){

        cp1 = "col-md-3";
        cp2 = "col-md-2";

      }else{

        cp1 = "col-md-5";
        cp2 = "col-md-2 hidden_div";

      }

      return (<>

          <div className="row rowTitlebaixo">

            <div className="col-md-2"></div>
            <div className={cp1}>Key</div>
            <div className="col-md-3">Status</div>
            <div className={cp2}>Editor</div>
            <div className="col-md-2"><center>Ações</center></div>

          </div>
          
          { (this.state.SortablePane) &&  

            <SortablePane resizable={{x:false,y:false,xy:false}}  onOrderChange={order => { this.updateOrder(order) }} dragHandleClassName="movedor" direction="vertical" margin={5}   onDragStop={order => { /*this.updateOrder(order); */}}  >
            
            {

              panes.map(function(value, i){

                
                return (<Pane resizable={{x:false,y:false,xy:false}} className="__resizable_base__" key={value.id} defaultSize={{ width: '100%',height:'20px'}} minHeight="20px">
             
                    <div className="row" idc={value.id}>

                      <div className="col-md-2" style={{whiteSpace:'nowrap'}}>
                        
                          <center>

                            <i className="fas fa-arrows-alt movedor" style={{marginRight:'10px'}}></i>

                            { (value.hidden === 1) && 
                              <i style={{cursor:'pointer'}} className="far fa-eye-slash" onClick={ () => window.studio_blockly.desocultarFile(value.id) }></i>
                            }
               
                            { (value.hidden !== 1) && 
                              <i style={{cursor:'pointer'}} className="far fa-eye" onClick={ () => window.studio_blockly.ocultarFile(value.id) }></i>
                            }
                            
                          </center>

                      </div> 

                      <div className={cp1}> <input onKeyUp={ (event) => window.studio_blockly.updateNameFile(value.id,event.target.value) } file={value.id} className="form-control input_baixo" defaultValue={value.name} /></div>
                        
                      

                      <div className="col-md-3">

                      <Tooltip placement="left" trigger={['hover']} overlay={<span>Define se o arquivo será usado.</span>}>
                        <div className="ckbx-style-14" >
                              <input id={`item-${value.id}`} defaultChecked={value.status} onChange={ (event) => window.studio_blockly.checkBoxStatus(event) } class="status_file_input" type="checkbox" value={value.id} name="ckbx-style-14" />
                              <label htmlFor={`item-${value.id}`} style={{borderRadius:'17px'}}></label>
                        </div>
                      </Tooltip>

                      </div>

                      <div className={cp2}>

                        <select className="selectTipoEditor" defaultValue={value.editor} onChange={ (event) => window.studio_blockly.updateFile(value.id,'editor',event.target.value) } >
                            
                            {   

                              window.defaultEditorOptions.map(function(item, i){
                                
                                return <option key={i} value={i} >{item}</option>

                              })

                            }

                        </select>

                      </div>

                      <div className="col-md-2 tes"> 

                        <center>

                          { (parseInt(value.editor) === 0) &&
                            <Tooltip hidden placement="bottom" trigger={['hover']} overlay={<span>Importar assets</span>}>
                              <i className="fas fa-file-import" style={{cursor:'pointer'}} onClick={ () => window.importassetscene.openModalImportAssetScene(value.id) }></i> 
                            </Tooltip>
                          }
                          
                          <Tooltip hidden placement="bottom" trigger={['hover']} overlay={<span>Remover</span>}>
                            <i className="far fa-trash-alt" style={{cursor:'pointer',marginLeft:'5px'}} onClick={ () => window.studio_blockly.removeFile(value.id) }></i>
                          </Tooltip>

                        </center>

                      </div>
                      
                    </div>
                  
                </Pane>)


              })

            }

            </SortablePane>
            
          }
          
        </>);
    }

}

export default FileEdit;