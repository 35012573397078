import React, { Component } from 'react';
//import $ from 'jquery';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

var cl,s;
const SortableItem = SortableElement(({value}) => { 
 	
 	cl = 'rdTab myTab react-draggable react-draggable-dragged'

	if(parseInt(value.id) === parseInt( window.studio_blockly.state.game.fileSelected )){ cl = cl+' ativo'; }

	if(value.hidden === 1){ s = {display:'none'}; }else{ s = {}; }

	var na = "";
	window.studio_blockly.state.Assets.forEach(function(item,index,array){ 

		if(item.file !== null){ 

			if(parseInt(item.file.id) === parseInt(value.id)){ na = item; } 

		}

	});
  	
  	var url_img;

	if(value.type === "asset"){ 

		if(na.upload !== undefined){ url_img = sessionStorage.getItem('backend')+na.upload.url; }else{ url_img = ""; }
	 

	}
 
	return( <li idc={value.id} className={cl} style={s}>
			{

				(value.type === "asset") &&
				<img onClick={ () => window.studio_blockly.preGetFile(value.id) }  src={url_img} style={{height:'20px',position:'absolute',top:'3px',left:'-10px',zIndex:'9999'}} alt=""/>

			}
			<span className="rdTabBeforeTitle"><span className="icon-html5">
			 
			

			{

				(value.type === "scene") &&
				<img onClick={ () => window.studio_blockly.preGetFile(value.id) } style={{height:'12px',marginLeft: '4px'}} src="/media/azul.png" alt=""/>
				
			}

			{

				(value.type === "json") &&
				<img onClick={ () => window.studio_blockly.preGetFile(value.id) } style={{height:'12px',marginLeft: '4px'}} src="/media/json.png" alt=""/>
				
			}

			</span></span>
			<p className="rdTabTitle myTabTitle" onClick={ () => window.studio_blockly.preGetFile(value.id) }>{value.name}</p>
			<span className="rdTabAfterTitle"><span></span></span>
			<span style={{zIndex:'99999'}} className="rdTabCloseIcon tabCloseIcon" onClick={ () => window.studio_blockly.ocultarFile(value.id) }>×</span>
			<span className="rdTabBefore myTabBefore"></span>
			<span className="rdTabAfter myTabAfter">  </span>

		</li>)

});

const SortableList = SortableContainer(({items,removeFile}) => {
  
 

	return (<ul className="fileListEs">{items.map((value,index) => {
		 
		
		for (let index2 = 0; index2 < window.studio_blockly.state.Assets.length; index2++) {
			
			if(window.studio_blockly.state.Assets[index2].file.id === value.id){

//				console.log(window.studio_blockly.state.Assets[index2]);
				if(window.studio_blockly.state.Assets[index2].type === 3){

					return (<React.Fragment  key={`item-${index}`}></React.Fragment>);

				}

			}
			
		}

		return(<SortableItem onClick={ () => console.log('criou') } key={`item-${index}`} index={index} value={value} />)

	})}</ul>);

});

class Files extends Component {
	 
	constructor(props){ 
	
		super(props); 
		window.tablefiles = this;
		
	}

	render() {
 
	    return (<div className="rdTabWrapper myWrapper">

				<select value={window.studio_blockly.state.game.toolBoxSelected} id="selectToolBox" onChange={ (event) => window.studio_blockly.preGetToolBox(event.target.value) }>
				{ window.studio_blockly.state.toolBoxList.map( window.studio_blockly.renderOption ) }
				</select>

				<SortableList distance={10} lockAxis="x" axis="x" helperClass="rdTab myTab" onSortEnd={window.studio_blockly.onSortFileEnd} items={window.studio_blockly.state.filesList}  />

				<div style={{cursor:'pointer'}} className="maisFile" onClick={ () => window.studio_blockly.newFileByName() }>+</div>

			</div>);

  	}


}

export default Files;
